var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', {
    attrs: {
      "title": "대량구매요청"
    }
  }, [_c('page-section', {
    staticClass: "py-0"
  }, [_c('form-input', {
    attrs: {
      "code": "bulk-purchase",
      "skin": "bulk-purchase"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }