<template>
    <client-page>
        <center-layout v-bind="{ title }">
            <page-section class="py-0">
                <form-input code="user-service" skin="user-service" v-bind="{ category }" />
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import FormInput from "@/components/client/form/form-input.vue";
import { USER_TYPES } from "@/assets/variables";

export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
        FormInput,
    },
    props: {
        category: { type: String, default: USER_TYPES.PERSON.value },
    },
    computed: {
        title() {
            return {
                [USER_TYPES.PERSON.value]: "개인회원 서비스 문의",
                [USER_TYPES.COMPANY.value]: "기업회원 서비스 문의",
            }[this.category];
        },
    },
};
</script>
