var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', {
    attrs: {
      "title": "JFL 케어",
      "subTitle": "딜리버리 설치 서비스"
    }
  }, [_c('page-section', {
    staticClass: "py-0"
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs line-height-135"
  }, [_vm._v("클릭 한 번으로 배달부터 설치까지 한번에!")]), _c('p', {
    staticClass: "txt txt--sm txt--dark mt-8px"
  }, [_vm._v("고객분들의 집이 예뻐지는 그 날까지 조이포라이프가 길잡이가 되어 드리겠습니다.")]), _c('p', {
    staticClass: "txt txt--xs txt--light mt-16px mt-md-20px"
  }, [_vm._v("*서비스 가능 지역을 먼저 확인해 주시고 설치비용은 제품 및 지역에 따라 다르게 책정될 수 있습니다.")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px",
    attrs: {
      "to": `${_vm.$route.path}/inquire`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("문의하기")])], 1)])])], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("배송·설치 서비스 리얼 고객 후기")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("제 로망을 이뤘어요~")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("신혼으로 우리만의 예쁜 욕실을 가지고 싶은 로망이 있어 출장 컨설팅을 신청했어요. 컨설팅을 통해서 제품과 조명, 타일까지 꼼꼼하게 상담해 주셔서 너무 예쁜 욕실이 완성된 거 같어요!")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[기업회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("신속한 처리 만족합니다.")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("조이포라이프 공식몰은 전담팀이 따로 있다보니 신속한 상담을 받을 수 있어 좋았습니다. 또한 공시일자가 앞당겨져서 택배로 받을 경우 일정을 맞추기 어려운 상황이었는데 상담원이 화물출고 처리를 도와줘서 일정을 맞출수 있었어요.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[개인회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("늘 애용하는 조이포라이프!!")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("주문한 상품의 부품 1개가 파손돼 고객센터로 문의했는데 빠르게 교환 처리를 받을 수 있었습니다. 굉장히 만족스럽네요. 전담팀이 있으니 이런 서비스들을 빨리 처리해 주셔서 계속 애용할 것 같습니다.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[기업회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("친절하고 좋아요!")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("시공일자에 맞추기 위해 특정 날짜에 수령 할 수 있도록 요청 했었어요~ 정말 그 날짜에 맞춰 늦지 않게 상품이 도착했더라구요. 차질없이 시공 잘 마무리 했습니다. 앞으로도 잘 부탁드리겠습니다. ㅎㅎ")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[개인회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("서비스 프로세스")])]), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 1")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("배송+설치서비스 신청")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("제품 구매시 해당 서비스 신청")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 2")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("해피콜-스케쥴 조정")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("주문 및 신청 확인 후 전담부서에서 연락")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 3")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("서비스 진행")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("전문 설치기사님이 배송 및 설치서비스 진행")])])], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("출장 컨설팅 신청 예시")])]), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("필히 현장에 대한 사진 및 영상을 보내주셔야 좀 더 정확한 컨설팅이 가능합니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("현장을 방문하지 못하는 관계로 정확한 설치 여부에 대해 파악이 어려울 수 있습니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("출장 컨설팅 서비스와 비교해 서비스 제한이 있을 수 있습니다.")])], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-20px",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px pa-xl-60px"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "740",
      "aspect-ratio": 740 / 576,
      "src": "/images/center/delivery-installation-service-img.png"
    }
  })], 1)])], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("현장 서비스 세부안내")])]), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 1")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("방문 현상 상황 체크")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("제품 설치 전 현장상황 점검 및 협의")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 2")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("보양 처리 및 제품 탈거")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("깨끗한 설치환경을 위한 보양 작업 및 탈거 진행 작업")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 3")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("구매 제품 설치")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("설치 메뉴얼에 따른 설치작업 진행")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 4")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("폐기물 수거/마무리")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("폐기물 수거 및 현장 클리닝 후 마무리")])])], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("배송·설치서비스 카테고리별 배송 방식")])]), _c('v-data-table', {
    staticClass: "v-data-table--default v-data-table--no-hover text-center",
    attrs: {
      "headers": _vm.ShippingMethodHeaders,
      "dense": "",
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "mobile-breakpoint": "0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tbody', [_c('tr', [_c('td', [_vm._v("택배서비스")]), _c('td'), _c('td', [_c('i', {
          staticClass: "icon icon-round-check mx-auto"
        })]), _c('td', [_c('i', {
          staticClass: "icon icon-round-check mx-auto"
        })]), _c('td'), _c('td')]), _c('tr', [_c('td', [_vm._v("퀵서비스")]), _c('td', [_c('i', {
          staticClass: "icon icon-round-check mx-auto"
        })]), _c('td', [_c('i', {
          staticClass: "icon icon-round-check mx-auto"
        })]), _c('td', [_c('i', {
          staticClass: "icon icon-round-check mx-auto"
        })]), _c('td', [_c('i', {
          staticClass: "icon icon-round-check mx-auto"
        })]), _c('td', [_c('i', {
          staticClass: "icon icon-round-check mx-auto"
        })])]), _c('tr', [_c('td', [_vm._v("전시장픽업서비스")]), _c('td', [_c('i', {
          staticClass: "icon icon-round-check mx-auto"
        })]), _c('td', [_c('i', {
          staticClass: "icon icon-round-check mx-auto"
        })]), _c('td', [_c('i', {
          staticClass: "icon icon-round-check mx-auto"
        })]), _c('td', [_c('i', {
          staticClass: "icon icon-round-check mx-auto"
        })]), _c('td', [_c('i', {
          staticClass: "icon icon-round-check mx-auto"
        })])])])];
      },
      proxy: true
    }])
  })], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("제품별 배송서비스 지역안내")])]), _c('v-data-table', {
    staticClass: "v-data-table--default v-data-table--no-hover text-center",
    attrs: {
      "headers": _vm.AreaGuideHeaders,
      "dense": "",
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "mobile-breakpoint": "0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tbody', [_c('tr', [_c('td', [_vm._v("택배서비스")]), _c('td', [_c('span', {
          staticClass: "red--text text--darken-3"
        }, [_vm._v("불가")])]), _c('td', [_vm._v("전국")]), _c('td', [_vm._v("전국")]), _c('td', [_c('span', {
          staticClass: "red--text text--darken-3"
        }, [_vm._v("불가")])]), _c('td', [_vm._v("전국")])]), _c('tr', [_c('td', [_vm._v("퀵서비스")]), _c('td', [_c('span', {
          staticClass: "primary--text"
        }, [_vm._v("서울/경기")])]), _c('td', [_vm._v("전국")]), _c('td', [_vm._v("전국")]), _c('td', [_c('span', {
          staticClass: "primary--text"
        }, [_vm._v("서울/경기")])]), _c('td', [_vm._v("전국")])]), _c('tr', [_c('td', [_vm._v("전시장픽업서비스")]), _c('td', [_vm._v("판교쇼룸")]), _c('td', [_vm._v("판교쇼룸")]), _c('td', [_vm._v("판교쇼룸")]), _c('td', [_vm._v("판교쇼룸")]), _c('td', [_vm._v("판교쇼룸")])])])];
      },
      proxy: true
    }])
  })], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("추가 비용 항목")])]), _c('v-row', {
    staticClass: "row--lg row-cols-xl-5",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "border border-dark rounded-pill"
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('div', {
    staticClass: "pa-14px text-center"
  }, [_c('i', {
    staticClass: "icon icon-carrying mx-auto mb-8px"
  }), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("배송 및 운반이 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("어려운 환경")])])])], 1)]), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "border border-dark rounded-pill"
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('div', {
    staticClass: "pa-14px text-center"
  }, [_c('i', {
    staticClass: "icon icon-texture mx-auto mb-8px"
  }), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("특수 재질인 경우")])])])], 1)]), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "border border-dark rounded-pill"
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('div', {
    staticClass: "pa-14px text-center"
  }, [_c('i', {
    staticClass: "icon icon-wiring mx-auto mb-8px"
  }), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("배선 이동 작업")])])])], 1)]), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "border border-dark rounded-pill"
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('div', {
    staticClass: "pa-14px text-center"
  }, [_c('i', {
    staticClass: "icon icon-perforated mx-auto mb-8px"
  }), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("타공 작업")])])])], 1)]), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "border border-dark rounded-pill"
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('div', {
    staticClass: "pa-14px text-center"
  }, [_c('i', {
    staticClass: "icon icon-expansion mx-auto mb-8px"
  }), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("증설 작업")])])])], 1)]), _c('v-col', {
    staticClass: "pt-lg-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "border border-dark rounded-pill"
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('div', {
    staticClass: "pa-14px text-center"
  }, [_c('i', {
    staticClass: "icon icon-bathroom-expansion mx-auto mb-8px"
  }), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("욕실 설비 증설 작업")])])])], 1)]), _c('v-col', {
    staticClass: "pt-lg-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "border border-dark rounded-pill"
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('div', {
    staticClass: "pa-14px text-center"
  }, [_c('i', {
    staticClass: "icon icon-electrical mx-auto mb-8px"
  }), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("욕실 전기 배선 작업")])])])], 1)]), _c('v-col', {
    staticClass: "pt-lg-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "border border-dark rounded-pill"
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('div', {
    staticClass: "pa-14px text-center"
  }, [_c('i', {
    staticClass: "icon icon-additional-perforation mx-auto mb-8px"
  }), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("제품 추가 타공 작업")])])])], 1)]), _c('v-col', {
    staticClass: "pt-lg-0",
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "border border-dark rounded-pill"
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('div', {
    staticClass: "pa-14px text-center"
  }, [_c('i', {
    staticClass: "icon icon-demolition mx-auto mb-8px"
  }), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("기타 추가 철거 작업")])])])], 1)])], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("배송·설치 서비스 비용산출방식")])]), _c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm primary--text font-weight-bold"
  }, [_vm._v("서비스비용 = ❶배송비 + ❷설치비 + ❸철거비 + ❹폐기물 수거비")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("욕조는 부피 및 무게로 인한 추가 비용이 발생할 수 있으니 고객센터에 꼭 확인하신 후 딜리버리 신청해 주시기 바랍니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("해당 서비스는 유료 서비스이며, 비용은 위 안내표를 확인해 주시기 바랍니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("조이포라이프 공식몰을 통해 구매하신 제품만 신청 가능한 서비스입니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("출장 비용은 지역에 따라서 변경 될 수 있습니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("환경에 따라 설치가 어려운 경우도 별도의 비용이 발생할 수 있습니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("제품 불량 외 설치 완료된 제품은 교환 및 반품이 불가합니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("기존 설치되어 있던 제품은 수거하지 않습니다. (확인요망)")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v(" 배선 및 타공 등 별도의 작업이 있을 시 추가 비용이 발생할 수 있으니 자세한 상담을 받아보시길 추천 드립니다. "), _c('p', {
    staticClass: "txt txt--xs red--text"
  }, [_vm._v("모든 환경이 동일하지 않으므로 추가적인 작업에 대한 정확한 정보는 현장 방문을 통해 확인 후 안내받으실 수 있습니다.")])])], 1)], 1)], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }