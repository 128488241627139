<template>
    <client-page>
        <center-layout title="JFL 케어" subTitle="안심 상담">
            <page-section class="page-section--sm pt-0">
                <v-card color="grey lighten-5">
                    <div class="pa-20px pa-md-40px">
                        <h3 class="tit tit--xs line-height-135">무엇이든 물어보세요! 조이포라이프가 성실히 답변해 드리겠습니다.</h3>
                        <p class="txt txt--sm txt--dark mt-8px">고객분들의 집이 예뻐지는 그 날까지 조이포라이프가 길잡이가 되어 드리겠습니다.</p>
                        <p class="txt txt--xs txt--light mt-16px mt-md-20px">*설치비용은 제품 및 지역에 따라 다르게 책정될 수 있습니다.</p>
                        <div class="btn-wrap">
                            <v-btn :to="`${$route.path}/inquire`" v-bind="{ ...btn_primary, ...$attrs }" class="min-w-120px">문의하기</v-btn>
                        </div>
                    </div>
                </v-card>
            </page-section>

            <v-divider />
            <page-section class="page-section--sm">
                <v-row class="ma-n10px ma-md-n20px ma-xl-n60px">
                    <!-- <v-col cols="12" lg="auto" class="pa-10px pa-md-20px pa-xl-60px">
                        <v-row class="ma-n8px ma-md-n12px">
                            <v-col cols="auto" class="pa-8px pa-md-12px">
                                <i class="icon icon-phone-lg" />
                            </v-col>
                            <v-col cols="auto" class="pa-8px pa-md-12px">
                                <div class="mb-8px">
                                    <v-chip color="grey darken-4" class="font-weight-bold">고객 만족 센터</v-chip>
                                </div>
                                <div class="tit">1644-2350</div>
                            </v-col>
                        </v-row>
                    </v-col> -->
                    <v-col cols="12" lg="" class="pa-10px pa-md-20px pa-xl-60px">
                        <p class="txt txt--sm txt--dark">조이포라이프를 이용해 주시는 고객님이 행복하고 편안하게 상품을 사용하실 수 있도록 적극적인 상담 서비스를 운영하고 있습니다. 상품 구매 전, 구매 과정, 배송 후 설치/관리까지 궁금하셨던 모든 사항에 대해 물어보세요. <br class="d-none d-md-block" />고객님이 안심하고 만족하실 수 있도록 성심 성의껏 답변드릴 것을 약속 드립니다.</p>
                    </v-col>
                </v-row>
            </page-section>
            <v-divider />

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">안내</h4>
                </div>

                <v-card img="/images/center/guide-img7.jpg">
                    <div class="pa-20px pa-md-40px pa-lg-60px">
                        <div class="text-center">
                            <div class="tit tit--xs white--text mb-8px">리모델링에 대해 무엇이든 물어보세요!</div>
                            <p class="txt txt--sm white--text">원하는 상품에 대한 정보, 옵션, 설치서비스는 어떻게 이용 할 수 있는지 등 조이포라이프 몰에 대해 <br class="d-none d-md-block" />궁금하신 사항이 있으면 무엇이든 물어보세요. 고객님께서 안심하고 만족하실 때까지 친절하게 답변 드리겠습니다.</p>
                        </div>
                        <div class="mt-20px mt-md-40px">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-card color="grey darken-4">
                                        <div class="pa-20px pa-md-30px pa-xl-40px text-center text-xl-left">
                                            <v-row align="center" justify="center" justify-xl="space-between">
                                                <v-col cols="auto" xl="">
                                                    <p class="txt txt--sm white--text font-weight-bold">상품에 대해 궁금한 것이 있다면?</p>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-btn color="white" class="min-w-120px">문의하기</v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-card color="primary">
                                        <div class="pa-20px pa-md-30px pa-xl-40px text-center text-xl-left">
                                            <v-row align="center" justify="center" justify-xl="space-between">
                                                <v-col cols="auto" xl="">
                                                    <p class="txt txt--sm white--text font-weight-bold">리모델링 고객들을 위한 맞춤 솔루션!</p>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-btn color="white" class="min-w-120px">문의하기</v-btn>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm">
                <v-row align="center" justify="space-between" class="row--xl">
                    <v-col cols="12" lg="auto" order-lg="2">
                        <div class="mw-200px mw-lg-none w-lg-320px mx-auto mb-n20px mb-lg-0">
                            <div style="padding-top: calc(400 / 320 * 100%); background: url(/images/center/consultation-img.png) no-repeat center/contain" class="w-100" />
                        </div>
                    </v-col>
                    <v-col cols="12" lg="" order-lg="1">
                        <div class="tit tit--xs mb-8px">전화연결이 어려우셨나요? ‘콜백 서비스’가 있습니다.</div>
                        <div class="txt txt--sm txt--dark">
                            <p>고객만족센터는 한 분이라도 놓치지 않고 상담 할 수 있도록 최선을 다합니다.</p>
                            <p class="mt-4px mt-md-8px">전화량이 많아 전화연결이 안 될 시 ‘콜백 서비스’를 운영하고 있으니 조금만 기다려 주시기 바랍니다. <br class="d-none d-md-block" />1차 부재 시 3회까지 시도하며, 그래도 연결이 안 될 시 문자를 발송해 드립니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </page-section>

            <v-divider />
            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">조이포라이프의 고객 만족 서비스</h4>
                </div>

                <v-row>
                    <v-col cols="12">
                        <v-card img="/images/center/guide-img8.jpg" class="img-card overflow-hidden h-100">
                            <v-responsive :aspect-ratio="1030 / 320" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold mb-8px">고객님의 의견에 귀를 기울입니다</div>
                                    <div class="txt txt--xs white--text">조이포라이프는 고객님이 안심하고 상담할 수 있도록 매주 고객만족도 조사를 실시하고 있습니다. 기업고객과 개인고객 리스트를 선별하여 조사를 진행하며, 조사를 통한 고객의 의견을 서비스에 충실히 반영하여 서비스 품질 개선에 적극 활용하고자 합니다. 자세한 내용은 문자를 통해 확인하실 수 있습니다.</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card img="/images/center/guide-img9.jpg" class="img-card overflow-hidden h-100">
                            <v-responsive :aspect-ratio="1030 / 320" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold mb-8px">고객님에게 품절리스트를 알립니다.</div>
                                    <div class="txt txt--xs white--text">주문한 제품 중 품절된 제품이 있을 경우에는 고객만족센터 담당자가 고객님게 직접 전화를 드립니다. 지정된 담당자는 제품이 입고되어 출고될 때까지 고객님을 케어하며, 고객님이 안심하고 제품을 받아 볼 수 있도록 합니다.</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card img="/images/center/guide-img10.jpg" class="img-card overflow-hidden h-100">
                            <v-responsive :aspect-ratio="1030 / 320" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold mb-8px">토요일에도 고객님을 위한 마음</div>
                                    <div class="txt txt--xs white--text">주문한 제품 중 품절된 제품이 있을 경우에는 고객만족센터 담당자가 고객님께 직접 전화를 드립니다. 지정된 담당자는 제품이 입고되어 출고될 때까지 고객님을 케어하며, 고객님이 안심하고 제품을 받아 볼 수 있도록 합니다.</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                </v-row>
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import { btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
    },
    data() {
        return {
            btn_primary,
        };
    },
};
</script>

<style lang="scss" scoped>
.gallery-img {
    width: 100%;
    padding-top: calc(164 / 248 * 100%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
</style>
