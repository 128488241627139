var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', _vm._b({}, 'center-layout', {
    title: _vm.title
  }, false), [_c('page-section', {
    staticClass: "py-0"
  }, [_c('form-input', _vm._b({
    attrs: {
      "code": "user-consulting",
      "skin": "user-consulting"
    }
  }, 'form-input', {
    category: _vm.category
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }