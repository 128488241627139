<template>
    <client-page>
        <center-layout title="JFL 케어" subTitle="온라인 컨설팅">
            <page-section class="py-0">
                <v-card color="grey lighten-5">
                    <div class="pa-20px pa-md-40px">
                        <h3 class="tit tit--xs line-height-135">바쁜 일상으로 출장 컨설팅 서비스를 이용하기 어려운 고객님<br />방문이 어려운 지역에 거주하시는 고객님<br />시간과 공간 제약없이 간편하고 쉽게 상담받기를 원하시는 고객님</h3>
                        <p class="txt txt--sm txt--dark mt-8px">고객분들의 집이 예뻐지는 그 날까지 조이포라이프가 길잡이가 되어 드리겠습니다.</p>
                        <p class="txt txt--xs txt--light mt-16px mt-md-20px">*기업회원 가입/전환은 사업자등록증의 소지여부로 결정됩니다.</p>
                        <div class="btn-wrap">
                            <v-btn :to="`${$route.path}/inquire`" v-bind="{ ...btn_primary, ...$attrs }" class="min-w-120px">문의하기</v-btn>
                        </div>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">온라인 컨설팅 리얼 고객 후기</h4>
                </div>

                <v-row>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">제 로망을 이뤘어요~</div>
                                    <div class="txt txt--xs">신혼으로 우리만의 예쁜 욕실을 가지고 싶은 로망이 있어 출장 컨설팅을 신청했어요. 컨설팅을 통해서 제품과 조명, 타일까지 꼼꼼하게 상담해 주셔서 너무 예쁜 욕실이 완성된 거 같어요!</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[기업회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">신속한 처리 만족합니다.</div>
                                    <div class="txt txt--xs">조이포라이프 공식몰은 전담팀이 따로 있다보니 신속한 상담을 받을 수 있어 좋았습니다. 또한 공시일자가 앞당겨져서 택배로 받을 경우 일정을 맞추기 어려운 상황이었는데 상담원이 화물출고 처리를 도와줘서 일정을 맞출수 있었어요.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[개인회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">늘 애용하는 조이포라이프!!</div>
                                    <div class="txt txt--xs">주문한 상품의 부품 1개가 파손돼 고객센터로 문의했는데 빠르게 교환 처리를 받을 수 있었습니다. 굉장히 만족스럽네요. 전담팀이 있으니 이런 서비스들을 빨리 처리해 주셔서 계속 애용할 것 같습니다.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[기업회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">친절하고 좋아요!</div>
                                    <div class="txt txt--xs">시공일자에 맞추기 위해 특정 날짜에 수령 할 수 있도록 요청 했었어요~ 정말 그 날짜에 맞춰 늦지 않게 상품이 도착했더라구요. 차질없이 시공 잘 마무리 했습니다. 앞으로도 잘 부탁드리겠습니다. ㅎㅎ</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[개인회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">서비스 내용 안내</h4>
                </div>

                <v-card outlined>
                    <div class="pa-20px pa-md-40px">
                        <v-row align="center" class="row--lg">
                            <v-col cols="12" xl="auto" order-xl="2" class="mx-auto">
                                <v-img :width="$vuetify.breakpoint.xlOnly ? 526 : ''" max-width="526" :aspect-ratio="526 / 332" class="mx-auto" src="/images/center/online-consulting-img.png" />
                            </v-col>
                            <v-col cols="12" xl="" order-xl="1">
                                <v-row class="row--lg">
                                    <v-col cols="12">
                                        <v-row class="row--xs">
                                            <v-col cols="12">
                                                <div class="txt txt--sm primary--text font-weight-bold">기본 컨설팅 내용</div>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>양변기/세면기 유형별 제품 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>세면수전/샤워수전/욕조수전/주방수전 타입별 제품 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>욕조 타입 및 소재별 제품 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>욕실가구 (상부장/하부장) 타입별 제품 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>액세서리 유형별 제품 상담</v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row class="row--xs">
                                            <v-col cols="12">
                                                <div class="txt txt--sm primary--text font-weight-bold">추가 컨설팅 내용</div>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>설치에 필요한 필수 점검 사항 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>현장 상황에 맞는 제품 제안 및 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>실시간 재고 현황 체크 및 주문 관련 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>대량 구매 상담</v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">서비스 신청시 유의사항</h4>
                </div>
                <v-row class="row--xs">
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>필히 현장에 대한 사진 및 영상을 보내주셔야 좀 더 정확한 컨설팅이 가능합니다.</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>현장을 방문하지 못하는 관계로 정확한 설치 여부에 대해 파악이 어려울 수 있습니다.</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>출장 컨설팅 서비스와 비교해 서비스 제한이 있을 수 있습니다.</v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">출장 컨설팅 신청 예시</h4>
                </div>
                <v-row class="row--xs">
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>필히 현장에 대한 사진 및 영상을 보내주셔야 좀 더 정확한 컨설팅이 가능합니다.</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>현장을 방문하지 못하는 관계로 정확한 설치 여부에 대해 파악이 어려울 수 있습니다.</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>출장 컨설팅 서비스와 비교해 서비스 제한이 있을 수 있습니다.</v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-card outlined class="mt-20px">
                    <div class="pa-20px pa-md-40px pa-xl-60px">
                        <v-img max-width="878" :aspect-ratio="878 / 610" class="mx-auto" src="/images/center/online-consulting-img2.png" />
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">서비스 프로세스</h4>
                </div>

                <v-row class="row--sm">
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 1</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">컨설팅 신청</span>
                                <!-- <span class="txt txt--sm txt--dark">고객센터 (1644-2350) 또는 게시판으로 신청</span> -->
                                <span class="txt txt--sm txt--dark">게시판으로 신청</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 2</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">담당자 컨설팅 진행</span>
                                <span class="txt txt--sm txt--dark">게시글 확인 후 게시판 및 카카오톡으로 온라인 컨설팅 진행</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 3</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">케어서비스</span>
                                <span class="txt txt--sm txt--dark">온라인 컨설팅 후 주문처리, 설치일정, 제품 입고 및 수령 확인 등 케어서비스 진행</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">주의사항</h4>
                </div>

                <v-card color="grey lighten-5">
                    <div class="pa-16px pa-md-20px">
                        <v-row class="row--xs">
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>온라인 컨설팅 서비스는 설치 서비스가 아닙니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>현장에 대한 사진 및 영상을 보내주셔야 좀 더 정확한 컨설팅이 가능합니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>현장 미방문으로 시공 가능 여부에 대해서는 정확한 상담이 어렵습니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>시공 비용 문의 시 환경에 따른 추가비용은 온라인상 확인이 불가능하며 기본 비용에 대해서만 안내가 가능합니다.</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import { btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
    },
    data() {
        return {
            btn_primary,
        };
    },
};
</script>

<style lang="scss" scoped>
.gallery-img {
    width: 100%;
    padding-top: calc(164 / 248 * 100%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
</style>
