<template>
    <client-page>
        <center-layout v-bind="{ title }">
            <page-section class="py-0">
                <form-input code="user-guide" skin="user-guide" v-bind="{ category }" />
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import FormInput from "@/components/client/form/form-input.vue";
import { BOARD_CATEGORIES__JFL_GUIDES } from "@/assets/variables";

export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
        FormInput,
    },
    props: {
        category: { type: String, default: BOARD_CATEGORIES__JFL_GUIDES.FIRST_TIMERS.value },
    },
    computed: {
        title() {
            return {
                [BOARD_CATEGORIES__JFL_GUIDES.FIRST_TIMERS.value]: `${BOARD_CATEGORIES__JFL_GUIDES.FIRST_TIMERS.text} 문의`,
                [BOARD_CATEGORIES__JFL_GUIDES.MUST_NOTICES.value]: `${BOARD_CATEGORIES__JFL_GUIDES.MUST_NOTICES.text} 문의`,
                [BOARD_CATEGORIES__JFL_GUIDES.INSTALLATION.value]: `${BOARD_CATEGORIES__JFL_GUIDES.INSTALLATION.text} 문의`,
            }[this.category];
        },
    },
};
</script>
