var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', _vm._b({
    attrs: {
      "title": "자주묻는 질문"
    }
  }, 'center-layout', {
    subTitle: _vm.subTitle
  }, false), [_c('page-section', {
    staticClass: "py-0"
  }, [_c('faq-list')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }