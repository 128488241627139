var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', {
    attrs: {
      "title": "JFL 가이드",
      "subTitle": "설치 서비스"
    }
  }, [_c('page-section', {
    staticClass: "py-0"
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs line-height-135"
  }, [_vm._v("조이포라이프가 제공하는 설치서비스에 대해 자세히 알아보실 수 있습니다.")]), _c('p', {
    staticClass: "txt txt--sm txt--dark mt-8px"
  }, [_vm._v("고객분들의 집이 예뻐지는 그 날까지 조이포라이프가 길잡이가 되어 드리겠습니다.")]), _c('p', {
    staticClass: "txt txt--xs txt--light mt-16px mt-md-20px"
  }, [_vm._v("*기업회원 가입/전환은 사업자등록증의 소지여부로 결정됩니다.")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px",
    attrs: {
      "to": `${_vm.$route.path}/inquire`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("문의하기")])], 1)])])], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("우리집에 꼭 맞는 제품을 고르는 Tip!")])]), _c('router-view', _vm._b({
    attrs: {
      "name": "board",
      "code": "jfl-guides",
      "skin": "jfl-guides",
      "limit": 8
    }
  }, 'router-view', {
    category: _vm.category
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }