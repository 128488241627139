var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--products"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "center-area"
  }, [_vm._m(0), _c('center-navigation'), _vm._m(1)], 1)])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "center-ban"
  }, [_c('h1', {
    staticClass: "center-ban__title"
  }, [_vm._v("조이포라이프 고객센터")]), _c('p', {
    staticClass: "center-ban__pr"
  }, [_vm._v("고객님과 함께하는 조이포라이프 고객센터 입니다.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "center-board"
  }, [_c('div', {
    staticClass: "terms-container"
  }, [_c('div', [_vm._v(" 이메일무단수집거부 ")])])]);

}]

export { render, staticRenderFns }