var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', {
    attrs: {
      "title": "JFL 케어",
      "subTitle": "안심 상담"
    }
  }, [_c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs line-height-135"
  }, [_vm._v("무엇이든 물어보세요! 조이포라이프가 성실히 답변해 드리겠습니다.")]), _c('p', {
    staticClass: "txt txt--sm txt--dark mt-8px"
  }, [_vm._v("고객분들의 집이 예뻐지는 그 날까지 조이포라이프가 길잡이가 되어 드리겠습니다.")]), _c('p', {
    staticClass: "txt txt--xs txt--light mt-16px mt-md-20px"
  }, [_vm._v("*설치비용은 제품 및 지역에 따라 다르게 책정될 수 있습니다.")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px",
    attrs: {
      "to": `${_vm.$route.path}/inquire`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("문의하기")])], 1)])])], 1), _c('v-divider'), _c('page-section', {
    staticClass: "page-section--sm"
  }, [_c('v-row', {
    staticClass: "ma-n10px ma-md-n20px ma-xl-n60px"
  }, [_c('v-col', {
    staticClass: "pa-10px pa-md-20px pa-xl-60px",
    attrs: {
      "cols": "12",
      "lg": ""
    }
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("조이포라이프를 이용해 주시는 고객님이 행복하고 편안하게 상품을 사용하실 수 있도록 적극적인 상담 서비스를 운영하고 있습니다. 상품 구매 전, 구매 과정, 배송 후 설치/관리까지 궁금하셨던 모든 사항에 대해 물어보세요. "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("고객님이 안심하고 만족하실 수 있도록 성심 성의껏 답변드릴 것을 약속 드립니다.")])])], 1)], 1), _c('v-divider'), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("안내")])]), _c('v-card', {
    attrs: {
      "img": "/images/center/guide-img7.jpg"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px pa-lg-60px"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit tit--xs white--text mb-8px"
  }, [_vm._v("리모델링에 대해 무엇이든 물어보세요!")]), _c('p', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v("원하는 상품에 대한 정보, 옵션, 설치서비스는 어떻게 이용 할 수 있는지 등 조이포라이프 몰에 대해 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("궁금하신 사항이 있으면 무엇이든 물어보세요. 고객님께서 안심하고 만족하실 때까지 친절하게 답변 드리겠습니다.")])]), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-xl-40px text-center text-xl-left"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center",
      "justify-xl": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "xl": ""
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text font-weight-bold"
  }, [_vm._v("상품에 대해 궁금한 것이 있다면?")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "min-w-120px",
    attrs: {
      "color": "white"
    }
  }, [_vm._v("문의하기")])], 1)], 1)], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-xl-40px text-center text-xl-left"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center",
      "justify-xl": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "xl": ""
    }
  }, [_c('p', {
    staticClass: "txt txt--sm white--text font-weight-bold"
  }, [_vm._v("리모델링 고객들을 위한 맞춤 솔루션!")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "min-w-120px",
    attrs: {
      "color": "white"
    }
  }, [_vm._v("문의하기")])], 1)], 1)], 1)])], 1)], 1)], 1)])])], 1), _c('page-section', {
    staticClass: "page-section--sm"
  }, [_c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "auto",
      "order-lg": "2"
    }
  }, [_c('div', {
    staticClass: "mw-200px mw-lg-none w-lg-320px mx-auto mb-n20px mb-lg-0"
  }, [_c('div', {
    staticClass: "w-100",
    staticStyle: {
      "padding-top": "calc(400 / 320 * 100%)",
      "background": "url(/images/center/consultation-img.png) no-repeat center/contain"
    }
  })])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "",
      "order-lg": "1"
    }
  }, [_c('div', {
    staticClass: "tit tit--xs mb-8px"
  }, [_vm._v("전화연결이 어려우셨나요? ‘콜백 서비스’가 있습니다.")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("고객만족센터는 한 분이라도 놓치지 않고 상담 할 수 있도록 최선을 다합니다.")]), _c('p', {
    staticClass: "mt-4px mt-md-8px"
  }, [_vm._v("전화량이 많아 전화연결이 안 될 시 ‘콜백 서비스’를 운영하고 있으니 조금만 기다려 주시기 바랍니다. "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("1차 부재 시 3회까지 시도하며, 그래도 연결이 안 될 시 문자를 발송해 드립니다.")])])])], 1)], 1), _c('v-divider'), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("조이포라이프의 고객 만족 서비스")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100",
    attrs: {
      "img": "/images/center/guide-img8.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1030 / 320,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold mb-8px"
  }, [_vm._v("고객님의 의견에 귀를 기울입니다")]), _c('div', {
    staticClass: "txt txt--xs white--text"
  }, [_vm._v("조이포라이프는 고객님이 안심하고 상담할 수 있도록 매주 고객만족도 조사를 실시하고 있습니다. 기업고객과 개인고객 리스트를 선별하여 조사를 진행하며, 조사를 통한 고객의 의견을 서비스에 충실히 반영하여 서비스 품질 개선에 적극 활용하고자 합니다. 자세한 내용은 문자를 통해 확인하실 수 있습니다.")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100",
    attrs: {
      "img": "/images/center/guide-img9.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1030 / 320,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold mb-8px"
  }, [_vm._v("고객님에게 품절리스트를 알립니다.")]), _c('div', {
    staticClass: "txt txt--xs white--text"
  }, [_vm._v("주문한 제품 중 품절된 제품이 있을 경우에는 고객만족센터 담당자가 고객님게 직접 전화를 드립니다. 지정된 담당자는 제품이 입고되어 출고될 때까지 고객님을 케어하며, 고객님이 안심하고 제품을 받아 볼 수 있도록 합니다.")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100",
    attrs: {
      "img": "/images/center/guide-img10.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1030 / 320,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold mb-8px"
  }, [_vm._v("토요일에도 고객님을 위한 마음")]), _c('div', {
    staticClass: "txt txt--xs white--text"
  }, [_vm._v("주문한 제품 중 품절된 제품이 있을 경우에는 고객만족센터 담당자가 고객님께 직접 전화를 드립니다. 지정된 담당자는 제품이 입고되어 출고될 때까지 고객님을 케어하며, 고객님이 안심하고 제품을 받아 볼 수 있도록 합니다.")])])])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }