<template>
    <v-form v-model="isValid">
        <page-section class="page-section--xs pb-0">
            <v-row class="row--xs">
                <template v-for="{ text, value } in categories">
                    <v-col cols="12" sm="6" :key="value">
                        <v-checkbox v-model="form.category" :value="value" color="grey darken-4" hide-details="auto" :label="text" />
                    </v-col>
                </template>
            </v-row>
        </page-section>

        <page-section class="page-section--xs pb-0">
            <v-row class="row--sm">
                <v-col cols="12">
                    <v-text-field v-model="form.writer.name" v-bind="{ ...attrs_input__secondary }" placeholder="이름" :rules="rules.name" />
                </v-col>
                <v-col cols="12">
                    <v-text-field v-model="form.writer.phone" :rules="rules.phone" v-bind="{ ...attrs_input__secondary }" placeholder="연락처" />
                </v-col>
                <v-col cols="12">
                    <div class="mb-n12px">
                        <terms-of-agreements-short v-model="form.meta._terms" hideItems @isValid="(isValid) => (isTermsValid = isValid)" />
                    </div>
                </v-col>
            </v-row>
        </page-section>

        <page-section class="page-section--xs pb-0">
            <v-btn v-bind="{ ...btn_primary, ...$attrs, }" block class="v-size--xx-large" @click="save">문의하기</v-btn>
        </page-section>

        <page-section class="page-section--xs pb-0">
            <v-btn v-bind="{ ...btn_secondary, ...$attrs, }" block to="/community" class="v-size--xx-large">커뮤니티</v-btn>
        </page-section>
    </v-form>
</template>

<script>
import api from "@/api";
import { attrs_input__secondary, CONCIERGE_SERVICE_CATEGORIES, initForm, rules } from "@/assets/variables";
import { btn_primary, btn_secondary } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";

const code = "concierge-service";
const categories = Object.values(CONCIERGE_SERVICE_CATEGORIES);

export default {
    components: {
        PageSection,
        TermsOfAgreementsShort,
    },
    data: () => ({
        btn_primary,
        btn_secondary,

        form: initForm({ code }),

        isValid: false,
        isTermsValid: false,

        loading: false,

        rules,
        categories,
        attrs_input__secondary,
    }),
    computed: {
        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
    },
    methods: {
        async save() {
            if (this.loading) return;
            else this.loading = true;
            try {
                await api.v1.forms.post(this.form);
                alert("문의가 등록되었습니다");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>