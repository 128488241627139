var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', _vm._b({
    attrs: {
      "title": "문의하기"
    }
  }, 'center-layout', {
    subTitle: _vm.subTitle
  }, false), [_vm.accessToken ? _c('div', [_vm.mode == 'list' ? _c('board-question-list', _vm._b({
    attrs: {
      "hideTypeFilter": ""
    }
  }, 'board-question-list', {
    code: _vm.code
  }, false)) : _vm.mode == 'view' ? _c('board-question-view', _vm._b({}, 'board-question-view', {
    code: _vm.code
  }, false)) : _vm.mode == 'form' ? _c('board-question-input', _vm._b({}, 'board-question-input', {
    code: _vm.code
  }, false)) : _vm._e()], 1) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }