<template>
    <client-page>
        <center-layout title="문의하기" v-bind="{ subTitle }">
            <div v-if="accessToken">
                <board-question-list v-if="mode == 'list'" v-bind="{ code }" hideTypeFilter />
                <board-question-view v-else-if="mode == 'view'" v-bind="{ code }" />
                <board-question-input v-else-if="mode == 'form'" v-bind="{ code }" />
            </div>
        </center-layout>
    </client-page>
</template>

<script>
import { CENTER_QUESTION_CODES, CENTER_QUESTION_TYPES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";

import BoardQuestionList from "@/components/client/board/skin/board-question/list.vue";
import BoardQuestionInput from "@/components/client/board/skin/board-question/input.vue";
import BoardQuestionView from "@/components/client/board/skin/board-question/view.vue";

const code = CENTER_QUESTION_CODES.INQUIRIES.value;

export default {
    components: {
        ClientPage,
        PageSection,
        CenterLayout,

        BoardQuestionList,
        BoardQuestionInput,
        BoardQuestionView,
    },
    data: () => ({
        code,
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        _question() {
            return this.$route.query._question;
        },
        mode() {
            if (this._question) return this.$route.query.mode || "view";
            else return this.$route.query.mode || "list";
        },
        type() {
            return this.$route.query.type;
        },
        subTitle() {
            return CENTER_QUESTION_TYPES[this.code][this.type]?.text || "전체보기";
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            if (!this.accessToken) {
                this.$router.replace("/login");
            }
        },
    },
};
</script>
