var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('community-layout', _vm._b({
    attrs: {
      "title": "커뮤니티"
    }
  }, 'community-layout', {
    subTitle: _vm.subTitle
  }, false), [_c('page-section', {
    staticClass: "py-0"
  }, [_c('router-view', _vm._b({
    attrs: {
      "name": "board",
      "code": "community",
      "skin": "board-community",
      "limit": 8
    }
  }, 'router-view', {
    category: _vm.category
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }