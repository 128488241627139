<template>
    <client-page>
        <center-layout title="JFL 케어" subTitle="출장 컨설팅">
            <page-section class="py-0">
                <v-card color="grey lighten-5">
                    <div class="pa-20px pa-md-40px">
                        <h3 class="tit tit--xs line-height-135">컨설턴트의 방문 상담을 원하시는 고객님<br />원하는 제품 설치 가능여부가 궁금하신 고객님<br />타일 등 전체적인 현장 실측이 필요하신 고객님</h3>
                        <p class="txt txt--sm txt--dark mt-8px">고객분들의 집이 예뻐지는 그 날까지 조이포라이프가 길잡이가 되어 드리겠습니다.</p>
                        <p class="txt txt--xs txt--light mt-16px mt-md-20px">*기업회원 가입/전환은 사업자등록증의 소지여부로 결정됩니다.</p>
                        <div class="btn-wrap">
                            <v-btn :to="`${$route.path}/inquire`" v-bind="{ ...btn_primary, ...$attrs }" class="min-w-120px">문의하기</v-btn>
                        </div>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">출장 컨설팅 리얼 고객 후기</h4>
                </div>

                <v-row>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">제 로망을 이뤘어요~</div>
                                    <div class="txt txt--xs">신혼으로 우리만의 예쁜 욕실을 가지고 싶은 로망이 있어 출장 컨설팅을 신청했어요. 컨설팅을 통해서 제품과 조명, 타일까지 꼼꼼하게 상담해 주셔서 너무 예쁜 욕실이 완성된 거 같어요!</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[기업회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">신속한 처리 만족합니다.</div>
                                    <div class="txt txt--xs">조이포라이프 공식몰은 전담팀이 따로 있다보니 신속한 상담을 받을 수 있어 좋았습니다. 또한 공시일자가 앞당겨져서 택배로 받을 경우 일정을 맞추기 어려운 상황이었는데 상담원이 화물출고 처리를 도와줘서 일정을 맞출수 있었어요.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[개인회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">늘 애용하는 조이포라이프!!</div>
                                    <div class="txt txt--xs">주문한 상품의 부품 1개가 파손돼 고객센터로 문의했는데 빠르게 교환 처리를 받을 수 있었습니다. 굉장히 만족스럽네요. 전담팀이 있으니 이런 서비스들을 빨리 처리해 주셔서 계속 애용할 것 같습니다.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[기업회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">친절하고 좋아요!</div>
                                    <div class="txt txt--xs">시공일자에 맞추기 위해 특정 날짜에 수령 할 수 있도록 요청 했었어요~ 정말 그 날짜에 맞춰 늦지 않게 상품이 도착했더라구요. 차질없이 시공 잘 마무리 했습니다. 앞으로도 잘 부탁드리겠습니다. ㅎㅎ</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[개인회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">출장 컨설팅 권역별 요금 안내</h4>
                </div>

                <v-card outlined>
                    <div class="pa-20px pa-md-40px">
                        <v-row align="center" class="row--lg">
                            <v-col cols="12" xl="auto" order-xl="2" class="mx-auto">
                                <v-img :width="$vuetify.breakpoint.xlOnly ? 514 : ''" max-width="514" :aspect-ratio="514 / 554" class="mx-auto" src="/images/center/business-trip-consulting-img.png" />
                            </v-col>
                            <v-col cols="12" xl="" order-xl="1">
                                <v-row class="row--lg">
                                    <v-col cols="12">
                                        <v-row class="row--xs">
                                            <v-col cols="12">
                                                <div class="txt txt--sm primary--text font-weight-bold">기본 컨설팅 내용</div>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>양변기/세면기 유형별 제품 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>세면수전/샤워수전/욕조수전/주방수전 타입별 제품 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>욕조 타입 및 소재별 제품 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>욕실가구 (상부장/하부장) 타입별 제품 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>액세서리 유형별 제품 상담</v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row class="row--xs">
                                            <v-col cols="12">
                                                <div class="txt txt--sm primary--text font-weight-bold">추가 컨설팅 내용</div>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>설치에 필요한 필수 점검 사항 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>현장 상황에 맞는 제품 제안 및 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>실시간 재고 현황 체크 및 주문 관련 상담</v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row class="row--xxs txt txt--sm txt--dark">
                                                    <v-col cols="auto">·</v-col>
                                                    <v-col>대량 구매 상담</v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <p class="txt txt--xs">*도서산간지역 제외<br />*출장 가능한 지역은 내부 사정에 의해 변경될 수 있습니다.</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
                <div class="pt-20px">
                    <v-data-table :items="BusinessTripConsultingItems" :headers="BusinessTripConsultingHeaders" dense hide-default-footer disable-filtering disable-sort mobile-breakpoint="0" class="v-data-table--default"> </v-data-table>
                </div>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">서비스 신청시 유의사항</h4>
                </div>
                <v-row class="row--xs">
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>출장 컨설팅 서비스는 제품 설치전 제공하는 서비스로 설치를 바로 진행하지 않습니다.</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>조이포라이프 공식몰을 통해 신청하신 분들에 한하여 가능한 케어 서비스 입니다.</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>해당 서비스는 모두 유료 서비스이며, 출장 비용은 선결제로 진행됩니다.</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>
                                출장 컨설팅 서비스 신청 완료일로부터 1일이 경과하면 출장 비용에 대한 환불은 불가한 점 양해 부탁드립니다.
                                <p class="txt txt--xs">(단, 출장 컨설팅 서비스 완료 이후 제품 구매시에는 출장 비용을 제품비에서 차감 시켜 드립니다.)</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-card outlined class="mt-20px">
                    <v-img max-width="1028" :aspect-ratio="1028 / 678" class="mx-auto" src="/images/center/business-trip-consulting-img2.jpg" />
                </v-card>
                <div class="btn-wrap text-center">
                    <v-btn v-bind="{ ...btn_primary, ...$attrs }" download="현장방문 체크리스트" href="/images/center/business-trip-consulting-img2.jpg" class="min-w-120px">현장방문 체크리스트 다운로드</v-btn>
                </div>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">서비스 프로세스</h4>
                </div>

                <v-row class="row--sm">
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 1</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">컨설팅 신청</span>
                                <!-- <span class="txt txt--sm txt--dark">고객센터 (1644-2350) 또는 게시판 으로 신청</span> -->
                                <span class="txt txt--sm txt--dark">게시판 으로 신청</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 2</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">해피콜: 스케쥴 조정</span>
                                <span class="txt txt--sm txt--dark">010으로 시작되는 번호로 연락 드립니다.</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 3</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">컨설턴트 방문</span>
                                <span class="txt txt--sm txt--dark">예약시간에 맞춰 신청하신 주소로 컨설턴트가 방문합니다.</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 4</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">컨설팅 완료</span>
                                <span class="txt txt--sm txt--dark">방문/컨설팅 후 주문처리, 설치일정, 제품 입고 및 수령 확인</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">주의사항</h4>
                </div>

                <v-card color="grey lighten-5">
                    <div class="pa-16px pa-md-20px">
                        <v-row class="row--xs">
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>컨설팅 서비스는 설치 서비스가 아닙니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>현장에서 제품 주문은 가능하나 제품은 별도로 발송됩니다. 수령일자는 제품마다 다를 수 있습니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>욕실 및 주방은 별도의 분야로 각 컨설턴트가 별도로 진행 할 수 있습니다.</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import { btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
    },
    data: () => {
        return {
            btn_primary,

            BusinessTripConsultingHeaders: [
                { text: "지역", value: "Title1", width: "6%", align: "center" },
                { text: "서울/경기", value: "Title2", align: "center" },
                { text: "인천광역시", value: "Title3", align: "center" },
                { text: "대전광역시", value: "Title4", align: "center" },
                { text: "광주광역시", value: "Title5", align: "center" },
                { text: "대구광역시", value: "Title6", align: "center" },
                { text: "울산광역시", value: "Title7", align: "center" },
                { text: "부산광역시", value: "Title8", align: "center" },
                { text: "제주특별시", value: "Title9", align: "center" },
            ],
            BusinessTripConsultingItems: [{ Title1: "비용", Title2: "35,000원", Title3: "40,000원", Title4: "40,000원", Title5: "45,000원", Title6: "45,000원", Title7: "50,000원", Title8: "60,000원", Title9: "150,000원" }],
        };
    },
};
</script>

<style lang="scss" scoped>
.gallery-img {
    width: 100%;
    padding-top: calc(164 / 248 * 100%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
</style>
