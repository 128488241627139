<template>
    <v-card tile tag="aside" width="100%" class="overflow-hidden">
        <v-divider class="grey darken-4" style="border-width: 2px 0 0" v-if="detect == 'pc'" />
        <aside-navigation v-bind="{ items }" />
    </v-card>
</template>

<script>
import detect from "@/plugins/detect";
import { FAQ_TYPES, CENTER_QUESTION_TYPES, CENTER_QUESTION_CODES } from "@/assets/variables";

import AsideNavigation from "@/components/client/dumb/aside-navigation.vue";

export default {
    components: {
        AsideNavigation,
    },
    setup: () => ({
        detect,
    }),
    computed: {
        items() {
            return [
                { text: "공지사항", to: { path: "/center/notice" } },
                {
                    text: "자주묻는 질문",
                    to: { path: "/center/faq" },
                    children: [
                        {
                            text: "전체보기",
                            to: { path: "/center/faq" },
                        },

                        ...Object.values(FAQ_TYPES).map(({ text, value }) => ({
                            text,
                            to: {
                                path: "/center/faq",
                                query: { type: value },
                            },
                        })),
                    ],
                },
                {
                    text: "문의하기",
                    to: { path: "/center/inquiry" },
                    children: [
                        {
                            text: "전체보기",
                            to: { path: "/center/inquiry" },
                            isActive: this.$route.path == "/center/inquiry" && !this.$route.query.type,
                        },

                        ...Object.values(CENTER_QUESTION_TYPES[CENTER_QUESTION_CODES.INQUIRIES.value]).map(({ text, value }) => ({
                            text,
                            to: {
                                path: "/center/inquiry",
                                query: { type: value },
                            },
                            isActive: this.$route.path == "/center/inquiry" && this.$route.query.type == value,
                        })),
                    ],
                },
                {
                    text: "개인 & 기업회원 서비스",
                    to: { path: "/center/individual-service" },
                    children: [
                        {
                            text: "개인회원 서비스",
                            to: { path: "/center/individual-service" },
                            isActive: this.$route.path.includes("/center/individual-service"),
                        },
                        {
                            text: "기업회원 서비스",
                            to: { path: "/center/corporate-service" },
                            isActive: this.$route.path.includes("/center/corporate-service"),
                        },
                    ],
                },
                {
                    text: "JFL 가이드",
                    to: { path: "/center/first-buying-guide" },
                    children: [
                        {
                            text: "첫 구매 가이드",
                            to: { path: "/center/first-buying-guide" },
                            isActive: this.$route.path.includes("/center/first-buying-guide"),
                        },
                        {
                            text: "필독 가이드",
                            to: { path: "/center/must-read-guide" },
                            isActive: this.$route.path.includes("/center/must-read-guide"),
                        },
                        {
                            text: "설치 서비스",
                            to: { path: "/center/installation-service" },
                            isActive: this.$route.path.includes("/center/installation-service"),
                        },
                    ],
                },
                {
                    text: "JFL 케어",
                    to: { path: "/center/business-trip-consulting" },
                    children: [
                        {
                            text: "출장 컨설팅",
                            to: { path: "/center/business-trip-consulting" },
                            isActive: this.$route.path.includes("/center/business-trip-consulting"),
                        },
                        {
                            text: "온라인 컨설팅",
                            to: { path: "/center/online-consulting" },
                            isActive: this.$route.path.includes("/center/online-consulting"),
                        },
                        {
                            text: "딜리버리 설치 서비스",
                            to: { path: "/center/delivery-installation-service" },
                            isActive: this.$route.path.includes("/center/delivery-installation-service"),
                        },
                        {
                            text: "전담 수리 서비스",
                            to: { path: "/center/dedicated-repair-service" },
                            isActive: this.$route.path.includes("/center/dedicated-repair-service"),
                        },
                        {
                            text: "안심 상담",
                            to: { path: "/center/consultation" },
                            isActive: this.$route.path.includes("/center/consultation"),
                        },
                    ],
                },
                {
                    text: "대량구매요청",
                    to: { path: "/center/bulk-purchase" },
                    isActive: this.$route.path.includes("/center/bulk-purchase"),
                },
            ];
        },
    },
};
</script>
