var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', {
    attrs: {
      "title": "개인 & 기업회원 서비스",
      "subTitle": "개인회원 서비스"
    }
  }, [_c('page-section', {
    staticClass: "py-0"
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs line-height-135"
  }, [_vm._v("회원 등급 및 멤버십 혜택")]), _c('p', {
    staticClass: "txt txt--sm txt--dark mt-8px"
  }, [_vm._v("조이포라이프 공식몰을 이용하시는 고객 여러분들께 더 좋은 서비스를 제공하고자 멤버십 제도로 운영하고 있으며 등급제를 통해 더 다양한 혜택을 드리고자 합니다. 고객 여러분께 만족스런 서비스와 합리적인 가격 혜택을 제공해 드리도록 노력하겠습니다.")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px",
    attrs: {
      "to": `${_vm.$route.path}/inquire`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("문의하기")])], 1)])])], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("개인(VIP)회원 리얼 고객 후기")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("덕분에 잘 설치했습니다.")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("조이포라이프는 기업전용 회선이 있더라구요. 급하게 주문할 제품을 전화 한통으로 신속하게 해결했습니다. 상담원 역시 친절히 안내해주셔서 아주 만족스럽게 설치 할 수 있었습니다.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[VIP]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("신속한 처리 만족합니다.")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("조이포라이프 공식몰은 전담팀이 따로 있다보니 신속한 상담을 받을 수 있어 좋았습니다. 또한 공시일자가 앞당겨져서 택배로 받을 경우 일정을 맞추기 어려운 상황이었는데 상담원이 화물출고 처리를 도와줘서 일정을 맞출수 있었어요.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[VIP]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("늘 애용하는 조이포라이프!!")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("주문한 상품의 부품 1개가 파손돼 고객센터로 문의했는데 빠르게 교환 처리를 받을 수 있었습니다. 굉장히 만족스럽네요. 전담팀이 있으니 이런 서비스들을 빨리 처리해 주셔서 계속 애용할 것 같습니다.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[VIP]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("친절하고 좋아요!")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("시공일자에 맞추기 위해 특정 날짜에 수령 할 수 있도록 요청 했었어요~ 정말 그 날짜에 맞춰 늦지 않게 상품이 도착했더라구요. 차질없이 시공 잘 마무리 했습니다. 앞으로도 잘 부탁드리겠습니다. ㅎㅎ")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[VIP]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("개인(VIP)회원을 위한 특별한 서비스")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "line-height-0 mb-20px mb-md-30px"
  }, [_c('icon-check-box')], 1), _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("구매금액 제한없이 할인")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("더 많은 혜택을 드릴 수 있도록 제품 1개를 구매하셔도 무조건 할인을 제공해 드립니다.")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "line-height-0 mb-20px mb-md-30px"
  }, [_c('icon-check-box')], 1), _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("VIP회원 특가 할인")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("더 많은 혜택을 드릴 수 있도록 제품 1개를 구매하셔도 무조건 할인을 제공해 드립니다.")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "line-height-0 mb-20px mb-md-30px"
  }, [_c('icon-check-box')], 1), _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("화물배송 서비스")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("더 많은 혜택을 드릴 수 있도록 제품 1개를 구매하셔도 무조건 할인을 제공해 드립니다.")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "line-height-0 mb-20px mb-md-30px"
  }, [_c('icon-check-box')], 1), _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("고객센터 전담팀 운영")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("일반 개인회원을 위한 적립금 혜택을 제공하고 있습니다. 구매금액의 1~4%를 회원등급에 따라 차등 지급해 드리고 있으며 주문시 더 저렴하게 구매하실 수 있습니다.")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "line-height-0 mb-20px mb-md-30px"
  }, [_c('icon-check-box')], 1), _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("원스탑(One-Stop) 서비스")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("일반 개인회원을 위한 적립금 혜택을 제공하고 있습니다. 구매금액의 1~4%를 회원등급에 따라 차등 지급해 드리고 있으며 주문시 더 저렴하게 구매하실 수 있습니다.")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "12",
      "lg": "4"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "line-height-0 mb-20px mb-md-30px"
  }, [_c('icon-check-box')], 1), _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("개인회원 적립혜택")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("일반 개인회원을 위한 적립금 혜택을 제공하고 있습니다. 구매금액의 1~4%를 회원등급에 따라 차등 지급해 드리고 있으며 주문 시 더 저렴하게 구매하실 수 있습니다.")])])])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("VIP 회원 서비스 안내")])]), _c('v-card', {
    attrs: {
      "img": "/images/center/guide-img12.jpg"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px pa-lg-60px"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "tit tit--lg white--text mb-8px"
  }, [_vm._v("VIP 회원 특별 혜택")]), _c('v-chip', {
    attrs: {
      "small": "",
      "color": "primary"
    }
  }, [_vm._v("2천만원 이상 구매 고객")])], 1), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center h-100",
    attrs: {
      "color": "rgba(0,0,0,0.7)"
    }
  }, [_c('div', {
    staticClass: "pa-20px text-center"
  }, [_c('p', {
    staticClass: "txt txt--sm white--text font-weight-bold"
  }, [_vm._v("무료 출장 서비스"), _c('br'), _c('span', {
    staticClass: "font-weight-regular"
  }, [_vm._v("(구매일로부터 연 2회 제공)")])])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center h-100",
    attrs: {
      "color": "rgba(0,0,0,0.7)"
    }
  }, [_c('div', {
    staticClass: "pa-20px text-center"
  }, [_c('p', {
    staticClass: "txt txt--sm white--text font-weight-bold"
  }, [_vm._v("무료 설치 서비스")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center h-100",
    attrs: {
      "color": "rgba(0,0,0,0.7)"
    }
  }, [_c('div', {
    staticClass: "pa-20px text-center"
  }, [_c('p', {
    staticClass: "txt txt--sm white--text font-weight-bold"
  }, [_vm._v("무료 배송 서비스")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center h-100",
    attrs: {
      "color": "rgba(0,0,0,0.7)"
    }
  }, [_c('div', {
    staticClass: "pa-20px text-center"
  }, [_c('p', {
    staticClass: "txt txt--sm white--text font-weight-bold"
  }, [_vm._v("파격적인 2% 적립금 지급")])])])], 1)], 1)], 1)])]), _c('div', {
    staticClass: "mt-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("욕조, 주방가구 등 사이즈가 큰 일부 품목의 경우 무료배송 제외됩니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("회원등급은 배송 완료 시점을 기준으로 자동 변경됩니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("회원등급 분류기준은 1년이며 구매이력이 없을 시 한 단계 다운그레이드 됩니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("각 쿠폰은 중복사용이 불가함을 원칙으로 합니다.")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }