var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', {
    attrs: {
      "title": "JFL 케어",
      "subTitle": "전담 수리 서비스"
    }
  }, [_c('page-section', {
    staticClass: "py-0"
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs line-height-135"
  }, [_vm._v("고장나도 안심하세요! 조이포라이프 CS팀이 책임지고 수리해 드립니다.")]), _c('p', {
    staticClass: "txt txt--sm txt--dark mt-8px"
  }, [_vm._v("고객분들의 집이 예뻐지는 그 날까지 조이포라이프가 길잡이가 되어 드리겠습니다.")]), _c('p', {
    staticClass: "txt txt--xs txt--light mt-16px mt-md-20px"
  }, [_vm._v("*설치비용은 제품 및 지역에 따라 다르게 책정될 수 있습니다.")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px",
    attrs: {
      "to": `${_vm.$route.path}/inquire`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("문의하기")])], 1)])])], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("전담 수리 서비스 리얼 고객 후기")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("제 로망을 이뤘어요~")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("신혼으로 우리만의 예쁜 욕실을 가지고 싶은 로망이 있어 출장 컨설팅을 신청했어요. 컨설팅을 통해서 제품과 조명, 타일까지 꼼꼼하게 상담해 주셔서 너무 예쁜 욕실이 완성된 거 같어요!")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[기업회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("신속한 처리 만족합니다.")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("조이포라이프 공식몰은 전담팀이 따로 있다보니 신속한 상담을 받을 수 있어 좋았습니다. 또한 공시일자가 앞당겨져서 택배로 받을 경우 일정을 맞추기 어려운 상황이었는데 상담원이 화물출고 처리를 도와줘서 일정을 맞출수 있었어요.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[개인회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("늘 애용하는 조이포라이프!!")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("주문한 상품의 부품 1개가 파손돼 고객센터로 문의했는데 빠르게 교환 처리를 받을 수 있었습니다. 굉장히 만족스럽네요. 전담팀이 있으니 이런 서비스들을 빨리 처리해 주셔서 계속 애용할 것 같습니다.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[기업회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("친절하고 좋아요!")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("시공일자에 맞추기 위해 특정 날짜에 수령 할 수 있도록 요청 했었어요~ 정말 그 날짜에 맞춰 늦지 않게 상품이 도착했더라구요. 차질없이 시공 잘 마무리 했습니다. 앞으로도 잘 부탁드리겠습니다. ㅎㅎ")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[개인회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("안내")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100",
    attrs: {
      "img": "/images/center/guide-img5.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 505 / 320,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold mb-8px"
  }, [_vm._v("적용 조건")]), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--xs white--text"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("구매 내역이 확인된 제품에 적용됩니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--xs white--text"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("보증기간이 지난 제품은 유상으로 수리 서비스를 진행합니다. "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("(차후 앱 다운로드 시 업그레이드, VIP는 무상 수리 서비스 1년 연장)")])], 1)], 1)], 1)], 1)])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100",
    attrs: {
      "img": "/images/center/guide-img6.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 505 / 320,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold mb-8px"
  }, [_vm._v("신청 방법")]), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--xs white--text"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("조이포라이프 공식몰 실시간 상담 및 카카오톡 상담")])], 1)], 1)], 1)], 1)])], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("추가 비용 항목")])]), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "border border-dark rounded-pill"
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 316 / 180,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('div', {
    staticClass: "pa-14px text-center"
  }, [_c('i', {
    staticClass: "icon icon-bathroom mx-auto mb-8px"
  }), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("욕실에 대한 전문성 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("보장된 기술인력")])])])], 1)]), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "border border-dark rounded-pill"
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 316 / 180,
      "content-class": "d-flex align-center justify-center"
    }
  }, [_c('div', {
    staticClass: "pa-14px text-center"
  }, [_c('i', {
    staticClass: "icon icon-as mx-auto mb-8px"
  }), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v(" 구입일로부터 1년 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 무상 A/S서비스 ")])])])], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }