<template>
    <client-page>
        <center-layout title="공지사항">
            <page-section class="py-0">
                <notification-list v-if="!$route.params._notification"></notification-list>
                <notification-view v-else></notification-view>
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";

export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
        NotificationList,
        NotificationView,
    },
};
</script>
