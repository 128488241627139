<template>
    <client-page>
        <center-layout title="JFL 가이드" subTitle="필독 가이드">
            <page-section class="py-0">
                <v-card color="grey lighten-5">
                    <div class="pa-20px pa-md-40px">
                        <h3 class="tit tit--xs line-height-135">조이포라이프 몰을 이용하시는 구매고객께서 편리하게 사용할 수 있도록 설치 관련 자료들을 모아두었습니다.</h3>
                        <p class="txt txt--sm txt--dark mt-8px">고객분들의 현명한 쇼핑을 위해 조이포라이프가 길잡이가 되어 드리겠습니다.</p>
                        <p class="txt txt--xs txt--light mt-16px mt-md-20px">*셀프로 설치 가능하나 스크래치 및 오작동의 원인이 될 수 있습니다. 따라서 자사 설치서비스를 이용하시는 것을 추천드립니다.</p>
                        <div class="btn-wrap">
                            <v-btn :to="`${$route.path}/inquire`" v-bind="{ ...btn_primary, ...$attrs }" class="min-w-120px">문의하기</v-btn>
                        </div>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">제품과 관련된 Tip!</h4>
                </div>

                <router-view name="board" code="jfl-guides" v-bind="{ category }" skin="jfl-guides" :limit="8" />
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">배송받기</h4>
                </div>
                <v-row>
                    <v-col cols="12" lg="6">
                        <v-card img="/images/center/guide-img.jpg" class="img-card overflow-hidden h-100">
                            <v-responsive :aspect-ratio="505 / 320" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold mb-8px">조이포라이프 안심배송</div>
                                    <div class="txt txt--xs white--text">숙련된 욕실 전문가들의 포장재 개발부터 리얼패킹 기술까지 오랜 수출 노하우를 바탕으로 한 조이포라이프의 배송 서비스를 경험해 보세요. 구매부터 설치까지 여러분의 소중한 제품을 안전하게 배송해 드리겠습니다.</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card img="/images/center/guide-img2.jpg" class="img-card overflow-hidden h-100">
                            <v-responsive :aspect-ratio="505 / 320" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold mb-8px">이제 안심하고 주문하세요.</div>
                                    <div class="txt txt--xs white--text">조이포라이프가 부지런히 움직이는 이유는 고객을 위한 마음 때문입니다. 최상의 제품을 안전하게 배송하고 첫 사용 시 달라지는 분위기에 기뻐하실 고객님의 모습을 생각하며 안심 배송해 드릴 수 있도록 최선을 다하겠습니다.</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card img="/images/center/guide-img3.jpg" class="img-card overflow-hidden h-100">
                            <v-responsive :aspect-ratio="505 / 320" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold mb-8px">소비자라는 마음으로 최선을 다하겠습니다.</div>
                                    <div class="txt txt--xs white--text">조이포라이프 배송 서비스의 가장 큰 자랑은 늘 고객의 입장에서 최선을 다하는 자세입니다. 오랜 수출경험을 바탕으로 한 숙련된 전문가들로 구성된 배송팀은 상품을 받고 기뻐할 고객님을 생각하며 최상의 제품이 배송될 수 있도록 출고 시 꼼꼼한 검수를 진행하고 있습니다.</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card img="/images/center/guide-img4.jpg" class="img-card overflow-hidden h-100">
                            <v-responsive :aspect-ratio="505 / 320" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold mb-8px">더욱 더 안전하게! 제품에 따른 이중패킹</div>
                                    <div class="txt txt--xs white--text">부피가 크거나 무게가 많이 나가 포장에 많은 시간이 소요되는 제품들은 별도로 추가적인 이중포장을 진행하여 안전하게 배송될 수 있도록 하고 있습니다. 나아가 통계적으로 불량, 파손이 많았던 제품들을 모니터링 하여 더 꼼곰하게 배송포장에 반영하고 있습니다.</div>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">설치하기</h4>
                </div>
                <v-card color="grey lighten-5">
                    <div class="pa-16px pa-md-20px">
                        <p class="txt txt--sm txt--dark">셀프 설치부터 딜리버리 서비스까지 고객이 원하는 서비스를 제공합니다.</p>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">A/S 신청</h4>
                </div>
                <v-card color="grey lighten-5">
                    <div class="pa-16px pa-md-20px">
                        <div class="tit-wrap tit-wrap--sm">
                            <h4 class="tit tit--xs">서비스신청방법</h4>
                        </div>

                        <v-row class="row--sm">
                            <v-col cols="12">
                                <v-row align-md="center" class="row--sm">
                                    <v-col cols="auto">
                                        <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 1</v-chip>
                                    </v-col>
                                    <v-col>
                                        <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">전담수리 서비스 문의</span>
                                        <span class="txt txt--sm txt--dark">해당 페이지 내 문의하기 / 고객센터 / 1:1 실시간 채팅 상담</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row align-md="center" class="row--sm">
                                    <v-col cols="auto">
                                        <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 2</v-chip>
                                    </v-col>
                                    <v-col>
                                        <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">CS전담팀 연락</span>
                                        <!-- <span class="txt txt--sm txt--dark">유선 혹은 카카오톡 상담 (대표번호 1644-2350)</span> -->
                                        <span class="txt txt--sm txt--dark">카카오톡 상담</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row align-md="center" class="row--sm">
                                    <v-col cols="auto">
                                        <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 3</v-chip>
                                    </v-col>
                                    <v-col>
                                        <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">방문일정협의</span>
                                        <span class="txt txt--sm txt--dark">서비스 매뉴얼에 따른진행</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row align-md="center" class="row--sm">
                                    <v-col cols="auto">
                                        <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 4</v-chip>
                                    </v-col>
                                    <v-col>
                                        <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">폐기물 수거/마무리</span>
                                        <span class="txt txt--sm txt--dark">폐기물 수거 및 현장 클리닝 후 마무리</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-card outlined class="mt-20px">
                            <div class="white pa-20px pa-md-40px pa-xl-60px">
                                <v-img max-width="876" :aspect-ratio="876 / 450" class="mx-auto" src="/images/center/guide-img13.png" />
                            </div>
                        </v-card>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">고객 과실 보상제도</h4>
                </div>
                <v-card color="grey lighten-5">
                    <div class="pa-16px pa-md-20px">
                        <p class="txt txt--sm txt--dark">2중,3중으로 꼼꼼한 포장을 하였음에도 불구하고 간혹 택배과정에서 상하차 시 제품이 손상되는 경우가 발생하기도 합니다. 이럴때는 조이포라이프 고객센터(070-4010-5061)로 연락주세요. 빠르게 해결해 드리겠습니다.</p>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">당일 발송 제품 운영</h4>
                </div>
                <v-card color="grey lighten-5">
                    <div class="pa-16px pa-md-20px">
                        <p class="txt txt--sm txt--dark">제품을 빠르게 받기를 원하실 경우 “당일배송” 혹은 “쇼룸픽업”을 신청하여 주시기 바랍니다. 평일 오후 2시 이전 결제 완료 시 익일 픽업가능 하도록 준비해두겠습니다.</p>
                        <p class="txt txt--sm txt--light">(타 제품과 함께 구매 시 당일발송 상품만 선 배송됩니다.)</p>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">서비스신청방법</h4>
                </div>

                <v-row class="row--sm">
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 1</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">전담수리 서비스 문의</span>
                                <span class="txt txt--sm txt--dark">해당 페이지 내 문의하기 / 고객센터 / 1:1 실시간 채팅 상담</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 2</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">CS전담팀 연락</span>
                                <!-- <span class="txt txt--sm txt--dark">유선 혹은 카카오톡 상담 (대표번호 1644-2350)</span> -->
                                <span class="txt txt--sm txt--dark">카카오톡 상담</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 3</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">방문일정협의</span>
                                <span class="txt txt--sm txt--dark">서비스 매뉴얼에 따른진행</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 4</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">폐기물 수거/마무리</span>
                                <span class="txt txt--sm txt--dark">폐기물 수거 및 현장 클리닝 후 마무리</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-card outlined class="mt-20px">
                    <div class="pa-20px pa-md-40px pa-xl-60px">
                        <v-img max-width="876" :aspect-ratio="876 / 450" class="mx-auto" src="/images/center/guide-img11.png" />
                    </div>
                </v-card>
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import { BOARD_CATEGORIES__JFL_GUIDES, btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
    },
    data: () => ({
        btn_primary,
        category: BOARD_CATEGORIES__JFL_GUIDES.MUST_NOTICES.value,
    }),
};
</script>
