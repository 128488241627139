var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', {
    attrs: {
      "title": "JFL 가이드",
      "subTitle": "첫 구매 가이드"
    }
  }, [_c('page-section', {
    staticClass: "py-0"
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs line-height-135"
  }, [_vm._v("조이포라이프 몰을 이용하시는 구매고객께 제안드립니다.")]), _c('p', {
    staticClass: "txt txt--sm txt--dark mt-8px"
  }, [_vm._v("고객분들의 현명한 쇼핑을 위해 조이포라이프가 길잡이가 되어 드리겠습니다.")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px",
    attrs: {
      "to": `${_vm.$route.path}/inquire`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("문의하기")])], 1)])])], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("우리집에 꼭 맞는 제품을 고르는 Tip!")])]), _c('router-view', _vm._b({
    attrs: {
      "name": "board",
      "code": "jfl-guides",
      "skin": "jfl-guides",
      "limit": 8
    }
  }, 'router-view', {
    category: _vm.category
  }, false))], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("배송받기")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100",
    attrs: {
      "img": "/images/center/guide-img.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 505 / 320,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold mb-8px"
  }, [_vm._v("조이포라이프 안심배송")]), _c('div', {
    staticClass: "txt txt--xs white--text"
  }, [_vm._v("숙련된 욕실 전문가들의 포장재 개발부터 리얼패킹 기술까지 오랜 수출 노하우를 바탕으로 한 조이포라이프의 배송 서비스를 경험해 보세요. 구매부터 설치까지 여러분의 소중한 제품을 안전하게 배송해 드리겠습니다.")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100",
    attrs: {
      "img": "/images/center/guide-img2.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 505 / 320,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold mb-8px"
  }, [_vm._v("이제 안심하고 주문하세요.")]), _c('div', {
    staticClass: "txt txt--xs white--text"
  }, [_vm._v("조이포라이프가 부지런히 움직이는 이유는 고객을 위한 마음 때문입니다. 최상의 제품을 안전하게 배송하고 첫 사용 시 달라지는 분위기에 기뻐하실 고객님의 모습을 생각하며 안심 배송해 드릴 수 있도록 최선을 다하겠습니다.")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100",
    attrs: {
      "img": "/images/center/guide-img3.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 505 / 320,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold mb-8px"
  }, [_vm._v("소비자라는 마음으로 최선을 다하겠습니다.")]), _c('div', {
    staticClass: "txt txt--xs white--text"
  }, [_vm._v("조이포라이프 배송 서비스의 가장 큰 자랑은 늘 고객의 입장에서 최선을 다하는 자세입니다. 오랜 수출경험을 바탕으로 한 숙련된 전문가들로 구성된 배송팀은 상품을 받고 기뻐할 고객님을 생각하며 최상의 제품이 배송될 수 있도록 출고 시 꼼꼼한 검수를 진행하고 있습니다.")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "img-card overflow-hidden h-100",
    attrs: {
      "img": "/images/center/guide-img4.jpg"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 505 / 320,
      "content-class": "d-flex pt-16px pt-md-40px"
    }
  }, [_c('div', {
    staticClass: "img-card__box pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "txt txt--sm white--text font-weight-bold mb-8px"
  }, [_vm._v("더욱 더 안전하게! 제품에 따른 이중패킹")]), _c('div', {
    staticClass: "txt txt--xs white--text"
  }, [_vm._v("부피가 크거나 무게가 많이 나가 포장에 많은 시간이 소요되는 제품들은 별도로 추가적인 이중포장을 진행하여 안전하게 배송될 수 있도록 하고 있습니다. 나아가 통계적으로 불량, 파손이 많았던 제품들을 모니터링 하여 더 꼼곰하게 배송포장에 반영하고 있습니다.")])])])], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("설치하기")])]), _c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("셀프 설치부터 딜리버리 서비스까지 고객이 원하는 서비스를 제공합니다.")])])])], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("A/S 신청")])]), _c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("서비스신청방법")])]), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 1")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("전담수리 서비스 문의")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("해당 페이지 내 문의하기 / 고객센터 / 1:1 실시간 채팅 상담")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 2")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("CS전담팀 연락")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("카카오톡 상담")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 3")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("방문일정협의")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("서비스 매뉴얼에 따른진행")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 4")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("폐기물 수거/마무리")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("폐기물 수거 및 현장 클리닝 후 마무리")])])], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-20px",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "white pa-20px pa-md-40px pa-xl-60px"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "876",
      "aspect-ratio": 876 / 450,
      "src": "/images/center/guide-img13.png"
    }
  })], 1)])], 1)])], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("고객 과실 보상제도")])]), _c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("2중,3중으로 꼼꼼한 포장을 하였음에도 불구하고 간혹 택배과정에서 상하차 시 제품이 손상되는 경우가 발생하기도 합니다. 이럴때는 조이포라이프 고객센터(070-4010-5061)로 연락주세요. 빠르게 해결해 드리겠습니다.")])])])], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("당일 발송 제품 운영")])]), _c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("제품을 빠르게 받기를 원하실 경우 “당일배송” 혹은 “쇼룸픽업”을 신청하여 주시기 바랍니다. 평일 오후 2시 이전 결제 완료 시 익일 픽업가능 하도록 준비해두겠습니다.")]), _c('p', {
    staticClass: "txt txt--sm txt--light"
  }, [_vm._v("(타 제품과 함께 구매 시 당일발송 상품만 선 배송됩니다.)")])])])], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("서비스신청방법")])]), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 1")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("전담수리 서비스 문의")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("해당 페이지 내 문의하기 / 고객센터 / 1:1 실시간 채팅 상담")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 2")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("CS전담팀 연락")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("카카오톡 상담")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 3")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("방문일정협의")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("서비스 매뉴얼에 따른진행")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 4")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("폐기물 수거/마무리")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("폐기물 수거 및 현장 클리닝 후 마무리")])])], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-20px",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px pa-xl-60px"
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "876",
      "aspect-ratio": 876 / 450,
      "src": "/images/center/guide-img11.png"
    }
  })], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }