var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('center-layout', {
    attrs: {
      "title": "JFL 케어",
      "subTitle": "출장 컨설팅"
    }
  }, [_c('page-section', {
    staticClass: "py-0"
  }, [_c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs line-height-135"
  }, [_vm._v("컨설턴트의 방문 상담을 원하시는 고객님"), _c('br'), _vm._v("원하는 제품 설치 가능여부가 궁금하신 고객님"), _c('br'), _vm._v("타일 등 전체적인 현장 실측이 필요하신 고객님")]), _c('p', {
    staticClass: "txt txt--sm txt--dark mt-8px"
  }, [_vm._v("고객분들의 집이 예뻐지는 그 날까지 조이포라이프가 길잡이가 되어 드리겠습니다.")]), _c('p', {
    staticClass: "txt txt--xs txt--light mt-16px mt-md-20px"
  }, [_vm._v("*기업회원 가입/전환은 사업자등록증의 소지여부로 결정됩니다.")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px",
    attrs: {
      "to": `${_vm.$route.path}/inquire`
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("문의하기")])], 1)])])], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("출장 컨설팅 리얼 고객 후기")])]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("제 로망을 이뤘어요~")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("신혼으로 우리만의 예쁜 욕실을 가지고 싶은 로망이 있어 출장 컨설팅을 신청했어요. 컨설팅을 통해서 제품과 조명, 타일까지 꼼꼼하게 상담해 주셔서 너무 예쁜 욕실이 완성된 거 같어요!")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[기업회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("신속한 처리 만족합니다.")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("조이포라이프 공식몰은 전담팀이 따로 있다보니 신속한 상담을 받을 수 있어 좋았습니다. 또한 공시일자가 앞당겨져서 택배로 받을 경우 일정을 맞추기 어려운 상황이었는데 상담원이 화물출고 처리를 도와줘서 일정을 맞출수 있었어요.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[개인회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("늘 애용하는 조이포라이프!!")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("주문한 상품의 부품 1개가 파손돼 고객센터로 문의했는데 빠르게 교환 처리를 받을 수 있었습니다. 굉장히 만족스럽네요. 전담팀이 있으니 이런 서비스들을 빨리 처리해 주셔서 계속 애용할 것 같습니다.")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[기업회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden h-100",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-8px"
  }, [_vm._v("친절하고 좋아요!")]), _c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("시공일자에 맞추기 위해 특정 날짜에 수령 할 수 있도록 요청 했었어요~ 정말 그 날짜에 맞춰 늦지 않게 상품이 도착했더라구요. 차질없이 시공 잘 마무리 했습니다. 앞으로도 잘 부탁드리겠습니다. ㅎㅎ")])]), _c('div', {
    staticClass: "d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px"
  }, [_c('span', {
    staticClass: "pr-8px"
  }, [_vm._v("[개인회원]")]), _c('span', {
    staticClass: "pr-8px"
  }, [_c('b', [_vm._v("joeylike")]), _vm._v("님")]), _c('v-rating', {
    staticClass: "mt-n2px",
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": 2
    }
  })], 1)])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("출장 컨설팅 권역별 요금 안내")])]), _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-40px"
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mx-auto",
    attrs: {
      "cols": "12",
      "xl": "auto",
      "order-xl": "2"
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "width": _vm.$vuetify.breakpoint.xlOnly ? 514 : '',
      "max-width": "514",
      "aspect-ratio": 514 / 554,
      "src": "/images/center/business-trip-consulting-img.png"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "",
      "order-xl": "1"
    }
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm primary--text font-weight-bold"
  }, [_vm._v("기본 컨설팅 내용")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("양변기/세면기 유형별 제품 상담")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("세면수전/샤워수전/욕조수전/주방수전 타입별 제품 상담")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("욕조 타입 및 소재별 제품 상담")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("욕실가구 (상부장/하부장) 타입별 제품 상담")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("액세서리 유형별 제품 상담")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm primary--text font-weight-bold"
  }, [_vm._v("추가 컨설팅 내용")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("설치에 필요한 필수 점검 사항 상담")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("현장 상황에 맞는 제품 제안 및 상담")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("실시간 재고 현황 체크 및 주문 관련 상담")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("대량 구매 상담")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "txt txt--xs"
  }, [_vm._v("*도서산간지역 제외"), _c('br'), _vm._v("*출장 가능한 지역은 내부 사정에 의해 변경될 수 있습니다.")])])], 1)], 1)], 1)], 1)]), _c('div', {
    staticClass: "pt-20px"
  }, [_c('v-data-table', {
    staticClass: "v-data-table--default",
    attrs: {
      "items": _vm.BusinessTripConsultingItems,
      "headers": _vm.BusinessTripConsultingHeaders,
      "dense": "",
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "mobile-breakpoint": "0"
    }
  })], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("서비스 신청시 유의사항")])]), _c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("출장 컨설팅 서비스는 제품 설치전 제공하는 서비스로 설치를 바로 진행하지 않습니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("조이포라이프 공식몰을 통해 신청하신 분들에 한하여 가능한 케어 서비스 입니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("해당 서비스는 모두 유료 서비스이며, 출장 비용은 선결제로 진행됩니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v(" 출장 컨설팅 서비스 신청 완료일로부터 1일이 경과하면 출장 비용에 대한 환불은 불가한 점 양해 부탁드립니다. "), _c('p', {
    staticClass: "txt txt--xs"
  }, [_vm._v("(단, 출장 컨설팅 서비스 완료 이후 제품 구매시에는 출장 비용을 제품비에서 차감 시켜 드립니다.)")])])], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-20px",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "1028",
      "aspect-ratio": 1028 / 678,
      "src": "/images/center/business-trip-consulting-img2.jpg"
    }
  })], 1), _c('div', {
    staticClass: "btn-wrap text-center"
  }, [_c('v-btn', _vm._b({
    staticClass: "min-w-120px",
    attrs: {
      "download": "현장방문 체크리스트",
      "href": "/images/center/business-trip-consulting-img2.jpg"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("현장방문 체크리스트 다운로드")])], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("서비스 프로세스")])]), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 1")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("컨설팅 신청")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("게시판 으로 신청")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 2")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("해피콜: 스케쥴 조정")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("010으로 시작되는 번호로 연락 드립니다.")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 3")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("컨설턴트 방문")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("예약시간에 맞춰 신청하신 주소로 컨설턴트가 방문합니다.")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align-md": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip', {
    staticClass: "font-weight-bold border-2",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("STEP 4")])], 1), _c('v-col', [_c('span', {
    staticClass: "txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px"
  }, [_vm._v("컨설팅 완료")]), _c('span', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("방문/컨설팅 후 주문처리, 설치일정, 제품 입고 및 수령 확인")])])], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm pb-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h4', {
    staticClass: "tit tit--xs"
  }, [_vm._v("주의사항")])]), _c('v-card', {
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-16px pa-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("컨설팅 서비스는 설치 서비스가 아닙니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("현장에서 제품 주문은 가능하나 제품은 별도로 발송됩니다. 수령일자는 제품마다 다를 수 있습니다.")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs txt txt--sm txt--dark"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("욕실 및 주방은 별도의 분야로 각 컨설턴트가 별도로 진행 할 수 있습니다.")])], 1)], 1)], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }