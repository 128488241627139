<template>
    <client-page>
        <center-layout title="개인 & 기업회원 서비스" subTitle="개인회원 서비스">
            <page-section class="py-0">
                <v-card color="grey lighten-5">
                    <div class="pa-20px pa-md-40px">
                        <h3 class="tit tit--xs line-height-135">회원 등급 및 멤버십 혜택</h3>
                        <p class="txt txt--sm txt--dark mt-8px">조이포라이프 공식몰을 이용하시는 고객 여러분들께 더 좋은 서비스를 제공하고자 멤버십 제도로 운영하고 있으며 등급제를 통해 더 다양한 혜택을 드리고자 합니다. 고객 여러분께 만족스런 서비스와 합리적인 가격 혜택을 제공해 드리도록 노력하겠습니다.</p>
                        <div class="btn-wrap">
                            <v-btn :to="`${$route.path}/inquire`" v-bind="{ ...btn_primary }" class="min-w-120px">문의하기</v-btn>
                        </div>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">개인(VIP)회원 리얼 고객 후기</h4>
                </div>

                <v-row>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">덕분에 잘 설치했습니다.</div>
                                    <div class="txt txt--xs">조이포라이프는 기업전용 회선이 있더라구요. 급하게 주문할 제품을 전화 한통으로 신속하게 해결했습니다. 상담원 역시 친절히 안내해주셔서 아주 만족스럽게 설치 할 수 있었습니다.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[VIP]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">신속한 처리 만족합니다.</div>
                                    <div class="txt txt--xs">조이포라이프 공식몰은 전담팀이 따로 있다보니 신속한 상담을 받을 수 있어 좋았습니다. 또한 공시일자가 앞당겨져서 택배로 받을 경우 일정을 맞추기 어려운 상황이었는데 상담원이 화물출고 처리를 도와줘서 일정을 맞출수 있었어요.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[VIP]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">늘 애용하는 조이포라이프!!</div>
                                    <div class="txt txt--xs">주문한 상품의 부품 1개가 파손돼 고객센터로 문의했는데 빠르게 교환 처리를 받을 수 있었습니다. 굉장히 만족스럽네요. 전담팀이 있으니 이런 서비스들을 빨리 처리해 주셔서 계속 애용할 것 같습니다.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[VIP]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">친절하고 좋아요!</div>
                                    <div class="txt txt--xs">시공일자에 맞추기 위해 특정 날짜에 수령 할 수 있도록 요청 했었어요~ 정말 그 날짜에 맞춰 늦지 않게 상품이 도착했더라구요. 차질없이 시공 잘 마무리 했습니다. 앞으로도 잘 부탁드리겠습니다. ㅎㅎ</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[VIP]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">개인(VIP)회원을 위한 특별한 서비스</h4>
                </div>

                <v-row>
                    <v-col cols="12" sm="6" md="12" lg="4">
                        <v-card color="grey lighten-5" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div class="line-height-0 mb-20px mb-md-30px">
                                    <icon-check-box />
                                </div>
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">구매금액 제한없이 할인</div>
                                    <div class="txt txt--xs">더 많은 혜택을 드릴 수 있도록 제품 1개를 구매하셔도 무조건 할인을 제공해 드립니다.</div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="4">
                        <v-card color="grey lighten-5" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div class="line-height-0 mb-20px mb-md-30px">
                                    <icon-check-box />
                                </div>
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">VIP회원 특가 할인</div>
                                    <div class="txt txt--xs">더 많은 혜택을 드릴 수 있도록 제품 1개를 구매하셔도 무조건 할인을 제공해 드립니다.</div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="4">
                        <v-card color="grey lighten-5" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div class="line-height-0 mb-20px mb-md-30px">
                                    <icon-check-box />
                                </div>
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">화물배송 서비스</div>
                                    <div class="txt txt--xs">더 많은 혜택을 드릴 수 있도록 제품 1개를 구매하셔도 무조건 할인을 제공해 드립니다.</div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="4">
                        <v-card color="grey lighten-5" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div class="line-height-0 mb-20px mb-md-30px">
                                    <icon-check-box />
                                </div>
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">고객센터 전담팀 운영</div>
                                    <div class="txt txt--xs">일반 개인회원을 위한 적립금 혜택을 제공하고 있습니다. 구매금액의 1~4%를 회원등급에 따라 차등 지급해 드리고 있으며 주문시 더 저렴하게 구매하실 수 있습니다.</div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="4">
                        <v-card color="grey lighten-5" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div class="line-height-0 mb-20px mb-md-30px">
                                    <icon-check-box />
                                </div>
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">원스탑(One-Stop) 서비스</div>
                                    <div class="txt txt--xs">일반 개인회원을 위한 적립금 혜택을 제공하고 있습니다. 구매금액의 1~4%를 회원등급에 따라 차등 지급해 드리고 있으며 주문시 더 저렴하게 구매하실 수 있습니다.</div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="4">
                        <v-card color="grey lighten-5" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div class="line-height-0 mb-20px mb-md-30px">
                                    <icon-check-box />
                                </div>
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">개인회원 적립혜택</div>
                                    <div class="txt txt--xs">일반 개인회원을 위한 적립금 혜택을 제공하고 있습니다. 구매금액의 1~4%를 회원등급에 따라 차등 지급해 드리고 있으며 주문 시 더 저렴하게 구매하실 수 있습니다.</div>
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">VIP 회원 서비스 안내</h4>
                </div>

                <v-card img="/images/center/guide-img12.jpg">
                    <div class="pa-20px pa-md-40px pa-lg-60px">
                        <div class="text-center">
                            <div class="tit tit--lg white--text mb-8px">VIP 회원 특별 혜택</div>
                            <v-chip small color="primary">2천만원 이상 구매 고객</v-chip>
                        </div>
                        <div class="mt-20px mt-md-40px">
                            <v-row class="row--xs">
                                <v-col cols="12" sm="6" md="3">
                                    <v-card color="rgba(0,0,0,0.7)" class="d-flex align-center justify-center h-100">
                                        <div class="pa-20px text-center">
                                            <p class="txt txt--sm white--text font-weight-bold">무료 출장 서비스<br /><span class="font-weight-regular">(구매일로부터 연 2회 제공)</span></p>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-card color="rgba(0,0,0,0.7)" class="d-flex align-center justify-center h-100">
                                        <div class="pa-20px text-center">
                                            <p class="txt txt--sm white--text font-weight-bold">무료 설치 서비스</p>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-card color="rgba(0,0,0,0.7)" class="d-flex align-center justify-center h-100">
                                        <div class="pa-20px text-center">
                                            <p class="txt txt--sm white--text font-weight-bold">무료 배송 서비스</p>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6" md="3">
                                    <v-card color="rgba(0,0,0,0.7)" class="d-flex align-center justify-center h-100">
                                        <div class="pa-20px text-center">
                                            <p class="txt txt--sm white--text font-weight-bold">파격적인 2% 적립금 지급</p>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-card>
                <div class="mt-20px">
                    <v-row class="row--xs">
                        <v-col cols="12">
                            <v-row class="row--xxs txt txt--sm txt--dark">
                                <v-col cols="auto">·</v-col>
                                <v-col>욕조, 주방가구 등 사이즈가 큰 일부 품목의 경우 무료배송 제외됩니다.</v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row class="row--xxs txt txt--sm txt--dark">
                                <v-col cols="auto">·</v-col>
                                <v-col>회원등급은 배송 완료 시점을 기준으로 자동 변경됩니다.</v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row class="row--xxs txt txt--sm txt--dark">
                                <v-col cols="auto">·</v-col>
                                <v-col>회원등급 분류기준은 1년이며 구매이력이 없을 시 한 단계 다운그레이드 됩니다.</v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row class="row--xxs txt txt--sm txt--dark">
                                <v-col cols="auto">·</v-col>
                                <v-col>각 쿠폰은 중복사용이 불가함을 원칙으로 합니다.</v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </page-section>

            <!-- <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">개인(VIP)회원 등록 방법</h4>
                </div>

                <span class="red--text">컨텐츠 미수급</span>
            </page-section> -->
        </center-layout>
    </client-page>
</template>

<script>
import { btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import IconCheckBox from "@/components/client/icons/icon-check-box.vue";

export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
        IconCheckBox,
    },
    data() {
        return {
            btn_primary,
        };
    },
};
</script>
