var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-check-box",
    attrs: {
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "d": "M25.1149 12.874C24.4493 12.874 23.9079 13.4154 23.9079 14.0809V24.5023C23.9079 25.1891 23.3493 25.7478 22.6625 25.7478H3.65927C2.97247 25.7478 2.41384 25.1891 2.41384 24.5023V5.49911C2.41384 4.81231 2.97247 4.25368 3.65927 4.25368H18.9848C19.6504 4.25368 20.1918 3.71229 20.1918 3.04676C20.1918 2.38123 19.6498 1.83984 18.9843 1.83984H3.65927C1.64141 1.83984 0 3.48125 0 5.49911V24.5023C0 26.5202 1.64141 28.1616 3.65927 28.1616H22.6625C24.6804 28.1616 26.3218 26.5202 26.3218 24.5023V14.0809C26.3218 13.4154 25.7804 12.874 25.1149 12.874Z"
    }
  }), _c('path', {
    staticClass: "check",
    attrs: {
      "d": "M29.6473 2.19387C29.4191 1.96571 29.1162 1.83984 28.7938 1.83984C28.4714 1.83984 28.1679 1.96571 27.9398 2.1933L15.3079 14.8252L10.6434 10.1613C10.1728 9.69057 9.40722 9.69057 8.93652 10.1613C8.46582 10.632 8.46582 11.3975 8.93652 11.8682L14.4527 17.3844C14.6797 17.6131 14.9832 17.739 15.3062 17.739C15.6292 17.739 15.9309 17.6143 16.1602 17.3861L29.6467 3.89965C30.1174 3.42895 30.1174 2.66342 29.6467 2.19272L29.6473 2.19387Z"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }