<template>
    <v-card tile tag="aside" width="100%" class="overflow-hidden">
        <v-divider class="grey darken-4" style="border-width: 2px 0 0" />
        <aside-navigation v-bind="{ items }" class="aside-list--row" />
    </v-card>
</template>

<script>
import { BOARD_CATEGORIES__COMMUNITY } from "@/assets/variables";

import AsideNavigation from "@/components/client/dumb/aside-navigation.vue";

export default {
    components: {
        AsideNavigation,
    },
    computed: {
        items() {
            return [
                {
                    text: "공지사항",
                    to: { path: "/community/notices" },
                    isActive: this.$route.path.includes(`/community/notices`),
                },
                {
                    text: "커뮤니티",
                    children: [
                        ...Object.values(BOARD_CATEGORIES__COMMUNITY).map(({ text, value }) => ({
                            text,
                            to: { path: `/community/${value}` },
                            isActive: this.$route.path.includes(`/community/${value}`),
                        })),
                    ],
                },
            ];
        },
    },
};
</script>
