<template>
    <client-page>
        <center-layout v-bind="{ title }">
            <page-section class="py-0">
                <form-input code="user-consulting" skin="user-consulting" v-bind="{ category }" />
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import FormInput from "@/components/client/form/form-input.vue";
import { FORM_CATEGORIES__JFL_CARES } from "@/assets/variables";

export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
        FormInput,
    },
    props: {
        category: { type: String, default: FORM_CATEGORIES__JFL_CARES.BUSINESS_TRIPS.value },
    },
    computed: {
        title() {
            return `${FORM_CATEGORIES__JFL_CARES[this.category]?.text || "-"} 문의`;
        },
    },
};
</script>
