<template>
    <client-page>
        <center-layout title="자주묻는 질문" v-bind="{ subTitle }">
            <page-section class="py-0">
                <faq-list />
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import FaqList from "@/components/client/center/faqs/faq-list.vue";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import { FAQ_TYPES } from "@/assets/variables";

export default {
    components: {
        FaqList,
        ClientPage,
        PageSection,
        CenterLayout,
    },
    computed: {
        subTitle() {
            return FAQ_TYPES[this.$route.query.type]?.text || "전체보기";
        },
    },
};
</script>
