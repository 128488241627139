<template>
    <client-page>
        <center-layout title="JFL 케어" subTitle="전담 수리 서비스">
            <page-section class="py-0">
                <v-card color="grey lighten-5">
                    <div class="pa-20px pa-md-40px">
                        <h3 class="tit tit--xs line-height-135">고장나도 안심하세요! 조이포라이프 CS팀이 책임지고 수리해 드립니다.</h3>
                        <p class="txt txt--sm txt--dark mt-8px">고객분들의 집이 예뻐지는 그 날까지 조이포라이프가 길잡이가 되어 드리겠습니다.</p>
                        <p class="txt txt--xs txt--light mt-16px mt-md-20px">*설치비용은 제품 및 지역에 따라 다르게 책정될 수 있습니다.</p>
                        <div class="btn-wrap">
                            <v-btn :to="`${$route.path}/inquire`" v-bind="{ ...btn_primary, ...$attrs }" class="min-w-120px">문의하기</v-btn>
                        </div>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">전담 수리 서비스 리얼 고객 후기</h4>
                </div>

                <v-row>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">제 로망을 이뤘어요~</div>
                                    <div class="txt txt--xs">신혼으로 우리만의 예쁜 욕실을 가지고 싶은 로망이 있어 출장 컨설팅을 신청했어요. 컨설팅을 통해서 제품과 조명, 타일까지 꼼꼼하게 상담해 주셔서 너무 예쁜 욕실이 완성된 거 같어요!</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[기업회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">신속한 처리 만족합니다.</div>
                                    <div class="txt txt--xs">조이포라이프 공식몰은 전담팀이 따로 있다보니 신속한 상담을 받을 수 있어 좋았습니다. 또한 공시일자가 앞당겨져서 택배로 받을 경우 일정을 맞추기 어려운 상황이었는데 상담원이 화물출고 처리를 도와줘서 일정을 맞출수 있었어요.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[개인회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">늘 애용하는 조이포라이프!!</div>
                                    <div class="txt txt--xs">주문한 상품의 부품 1개가 파손돼 고객센터로 문의했는데 빠르게 교환 처리를 받을 수 있었습니다. 굉장히 만족스럽네요. 전담팀이 있으니 이런 서비스들을 빨리 처리해 주셔서 계속 애용할 것 같습니다.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[기업회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">친절하고 좋아요!</div>
                                    <div class="txt txt--xs">시공일자에 맞추기 위해 특정 날짜에 수령 할 수 있도록 요청 했었어요~ 정말 그 날짜에 맞춰 늦지 않게 상품이 도착했더라구요. 차질없이 시공 잘 마무리 했습니다. 앞으로도 잘 부탁드리겠습니다. ㅎㅎ</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[개인회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">안내</h4>
                </div>

                <v-row>
                    <v-col cols="12" lg="6">
                        <v-card img="/images/center/guide-img5.jpg" class="img-card overflow-hidden h-100">
                            <v-responsive :aspect-ratio="505 / 320" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold mb-8px">적용 조건</div>
                                    <v-row class="row--xs">
                                        <v-col cols="12">
                                            <v-row class="row--xxs txt txt--xs white--text">
                                                <v-col cols="auto">·</v-col>
                                                <v-col>구매 내역이 확인된 제품에 적용됩니다.</v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row class="row--xxs txt txt--xs white--text">
                                                <v-col cols="auto">·</v-col>
                                                <v-col>보증기간이 지난 제품은 유상으로 수리 서비스를 진행합니다. <br class="d-none d-md-block" />(차후 앱 다운로드 시 업그레이드, VIP는 무상 수리 서비스 1년 연장)</v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card img="/images/center/guide-img6.jpg" class="img-card overflow-hidden h-100">
                            <v-responsive :aspect-ratio="505 / 320" content-class="d-flex pt-16px pt-md-40px">
                                <div class="img-card__box pa-16px pa-md-20px">
                                    <div class="txt txt--sm white--text font-weight-bold mb-8px">신청 방법</div>
                                    <v-row class="row--xs">
                                        <!-- <v-col cols="12">
                                            <v-row class="row--xxs txt txt--xs white--text">
                                                <v-col cols="auto">·</v-col>
                                                <v-col> 고객센터 1644-2350 <br class="d-none d-md-block" />월~금 / 토요일 (여부 확인 필요) </v-col>
                                            </v-row>
                                        </v-col> -->
                                        <v-col cols="12">
                                            <v-row class="row--xxs txt txt--xs white--text">
                                                <v-col cols="auto">·</v-col>
                                                <v-col>조이포라이프 공식몰 실시간 상담 및 카카오톡 상담</v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-responsive>
                        </v-card>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">추가 비용 항목</h4>
                </div>

                <v-row justify="center" class="row--lg">
                    <v-col cols="6" lg="4">
                        <div class="border border-dark rounded-pill">
                            <v-responsive :aspect-ratio="316 / 180" content-class="d-flex align-center justify-center">
                                <div class="pa-14px text-center">
                                    <i class="icon icon-bathroom mx-auto mb-8px" />
                                    <div class="txt txt--sm txt--dark">욕실에 대한 전문성 <br class="d-none d-md-block" />보장된 기술인력</div>
                                </div>
                            </v-responsive>
                        </div>
                    </v-col>
                    <!-- <v-col cols="6" lg="4">
                        <div class="border border-dark rounded-pill">
                            <v-responsive :aspect-ratio="316 / 180" content-class="d-flex align-center justify-center">
                                <div class="pa-14px text-center">
                                    <i class="icon icon-phone mx-auto mb-8px" />
                                    <div class="txt txt--sm txt--dark">
                                        1644-2350 <br class="d-none d-md-block" />
                                        실시간 상담 고객 만족 센터
                                    </div>
                                </div>
                            </v-responsive>
                        </div>
                    </v-col> -->
                    <v-col cols="6" lg="4">
                        <div class="border border-dark rounded-pill">
                            <v-responsive :aspect-ratio="316 / 180" content-class="d-flex align-center justify-center">
                                <div class="pa-14px text-center">
                                    <i class="icon icon-as mx-auto mb-8px" />
                                    <div class="txt txt--sm txt--dark">
                                        구입일로부터 1년 <br class="d-none d-md-block" />
                                        무상 A/S서비스
                                    </div>
                                </div>
                            </v-responsive>
                        </div>
                    </v-col>
                </v-row>
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import { btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
    },
    data() {
        return {
            btn_primary,
        };
    },
};
</script>

<style lang="scss" scoped>
.gallery-img {
    width: 100%;
    padding-top: calc(164 / 248 * 100%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
</style>
