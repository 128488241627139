<template>
    <client-page>
        <center-layout title="JFL 가이드" subTitle="설치 서비스">
            <page-section class="py-0">
                <v-card color="grey lighten-5">
                    <div class="pa-20px pa-md-40px">
                        <h3 class="tit tit--xs line-height-135">조이포라이프가 제공하는 설치서비스에 대해 자세히 알아보실 수 있습니다.</h3>
                        <p class="txt txt--sm txt--dark mt-8px">고객분들의 집이 예뻐지는 그 날까지 조이포라이프가 길잡이가 되어 드리겠습니다.</p>
                        <p class="txt txt--xs txt--light mt-16px mt-md-20px">*기업회원 가입/전환은 사업자등록증의 소지여부로 결정됩니다.</p>
                        <div class="btn-wrap">
                            <v-btn :to="`${$route.path}/inquire`" v-bind="{ ...btn_primary, ...$attrs }" class="min-w-120px">문의하기</v-btn>
                        </div>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">우리집에 꼭 맞는 제품을 고르는 Tip!</h4>
                </div>

                <router-view name="board" code="jfl-guides" v-bind="{ category }" skin="jfl-guides" :limit="8" />
            </page-section>

            <!-- <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">배송·설치 서비스 단가표 (해당서비스 신청 시 배송비 자동 포함)</h4>
                </div>
                <v-data-table dense hide-default-footer disable-filtering disable-sort mobile-breakpoint="0" class="v-data-table--default v-data-table--border v-data-table--no-hover text-center" style="max-width: 1030px">
                    <template #header>
                        <thead>
                            <tr>
                                <th rowspan="2">구분</th>
                                <th rowspan="2">세부항목</th>
                                <th colspan="2"><span class="primary--text">서비스 비용</span></th>
                                <th colspan="4">세부내역</th>
                                <th rowspan="2">운영방식</th>
                                <th rowspan="2">배송방식</th>
                                <th rowspan="2">비고</th>
                            </tr>
                            <tr>
                                <th class="border-l">콜러</th>
                                <th>기타브랜드<br /><span class="font-size-12 grey--text text--lighten-1">(부속비 별도)</span></th>
                                <th>출장비</th>
                                <th>탈거비</th>
                                <th>설치비</th>
                                <th>수거비</th>
                            </tr>
                        </thead>
                    </template>
                    <template #body>
                        <tbody>
                            <tr>
                                <td rowspan="6"><span class="font-weight-medium grey--text text--darken-4">세면수전</span></td>
                                <td>1홀수전</td>
                                <td>108,000원</td>
                                <td>95,000원</td>
                                <td>35,000원</td>
                                <td>10,000원</td>
                                <td>40,000원</td>
                                <td>10,000원</td>
                                <td></td>
                                <td rowspan="10">경동택배</td>
                                <td rowspan="24" class="text-left">
                                    <span class="grey--text text--darken-4">* 추가비용안내</span><br />
                                    3층 이상부터 추가 비용 발생<br />
                                    (추가부분 현장에서 직접 결제)<br />
                                    지역별, 상황별로 상이<br />
                                    <br />
                                    - 욕실 매립 설비 증설 작업<br />
                                    - 욕실 전기 설비 배선 작업<br />
                                    - 제품 추가 타공 작업<br />
                                    <span class="primary--text">
                                        - 콜러 외 기타브랜드 설치<br />
                                        서비스 비용 별도
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td class="border-l">2홀수전</td>
                                <td>226,000원</td>
                                <td>210,000원</td>
                                <td>35,000원</td>
                                <td>10,000원</td>
                                <td>120,000원</td>
                                <td>10,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="border-l">3홀수전</td>
                                <td>226,000원</td>
                                <td>210,000원</td>
                                <td>35,000원</td>
                                <td>10,000원</td>
                                <td>120,000원</td>
                                <td>10,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="border-l">탑볼수전</td>
                                <td>226,000원</td>
                                <td>95,000원</td>
                                <td>35,000원</td>
                                <td>10,000원</td>
                                <td>68,000원</td>
                                <td>10,000원</td>
                                <td>
                                    <span class="red--text">설치서비스<br />상담문의</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="border-l"><span class="secondary--text">*벽매립수전</span></td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>
                                    <span class="red--text">출장컨설팅<br />상담문의</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="border-l">센서수전</td>
                                <td>108,000원</td>
                                <td>95,000원</td>
                                <td>35,000원</td>
                                <td>10,000원</td>
                                <td>120,000원</td>
                                <td>10,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td rowspan="4">샤워욕조<br />수전</td>
                                <td>레인샤워</td>
                                <td>145,000원</td>
                                <td>145,000원</td>
                                <td>35,000원</td>
                                <td>10,000원</td>
                                <td>130,000원</td>
                                <td>10,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="border-l">샤워욕조수전</td>
                                <td>145,000원</td>
                                <td>145,000원</td>
                                <td>35,000원</td>
                                <td>10,000원</td>
                                <td>130,000원</td>
                                <td>10,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="border-l">싱크수전</td>
                                <td>122,000원</td>
                                <td>115,000원</td>
                                <td>35,000원</td>
                                <td>10,000원</td>
                                <td>60,000원</td>
                                <td>10,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="border-l">
                                    <span class="secondary--text">*매립형<br />샤워욕조수전</span>
                                </td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>
                                    <span class="red--text">출장컨설팅<br />상담문의</span>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="5">세면기</td>
                                <td>탑볼세면기</td>
                                <td>213,000원</td>
                                <td>205,000원</td>
                                <td>35,000원</td>
                                <td>20,000원</td>
                                <td>200,000원</td>
                                <td>15,000원</td>
                                <td>
                                    <span class="red--text">설치서비스<br />상담문의</span>
                                </td>
                                <td rowspan="14">퀵서비스</td>
                            </tr>
                            <tr>
                                <td class="border-l">반매립세면기</td>
                                <td>213,000원</td>
                                <td>205,000원</td>
                                <td>35,000원</td>
                                <td>20,000원</td>
                                <td>200,000원</td>
                                <td>15,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="border-l">벽걸이세면기</td>
                                <td>213,000원</td>
                                <td>205,000원</td>
                                <td>35,000원</td>
                                <td>20,000원</td>
                                <td>200,000원</td>
                                <td>15,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="border-l">스탠딩세면기</td>
                                <td>213,000원</td>
                                <td>205,000원</td>
                                <td>35,000원</td>
                                <td>20,000원</td>
                                <td>200,000원</td>
                                <td>15,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="border-l"><span class="secondary--text">*언더카운터</span></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <span class="red--text">설치서비스<br />상담문의</span>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="5">양변기</td>
                                <td>비데일체형</td>
                                <td>361,000원</td>
                                <td>355,000원</td>
                                <td>10,000원</td>
                                <td>20,000원</td>
                                <td>300,000원</td>
                                <td>15,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="border-l">1PC</td>
                                <td>231,000원</td>
                                <td>225,000원</td>
                                <td>10,000원</td>
                                <td>20,000원</td>
                                <td>200,000원</td>
                                <td>15,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="border-l">2PC</td>
                                <td>231,000원</td>
                                <td>225,000원</td>
                                <td>10,000원</td>
                                <td>20,000원</td>
                                <td>200,000원</td>
                                <td>15,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="border-l"><span class="secondary--text">*소변기</span></td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>
                                    <span class="red--text">출장컨설팅<br />상담문의</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="border-l"><span class="secondary--text">*벽걸이</span></td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>
                                    <span class="red--text">출장컨설팅<br />상담문의</span>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2">욕조</td>
                                <td><span class="secondary--text">*매립형 욕조</span></td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>
                                    <span class="red--text">출장컨설팅<br />상담문의</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="border-l">
                                    <span class="secondary--text">*프리스탠딩<br />욕조</span>
                                </td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>
                                    <span class="red--text">출장컨설팅<br />상담문의</span>
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="2">가구</td>
                                <td>*거울장</td>
                                <td>115,000원</td>
                                <td>115,000원</td>
                                <td>10,000원</td>
                                <td>20,000원</td>
                                <td>70,000원</td>
                                <td>15,000원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="border-l">*하부장</td>
                                <td>195,000원</td>
                                <td>195,000원</td>
                                <td>10,000원</td>
                                <td>20,000원</td>
                                <td>150,000원</td>
                                <td>15,000원</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>

                <v-card color="grey lighten-5" class="mt-20px">
                    <div class="pa-16px pa-md-20px">
                        <v-row class="row--xs">
                            <v-col cols="12">
                                <div class="txt txt--sm primary--text font-weight-bold">* 서비스비용 = ❶출장비 + ❷탈거비 + ❸설치비 + ❹폐기물 수거비</div>
                            </v-col>
                            <v-col cols="12">
                                <div class="txt txt--sm primary--text font-weight-bold">* 배송비의 경우 도기류는 퀵배송을 원칙으로 한다.</div>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>매립 제품 설치 시 사전에 반드시 협의가 필요하며 매립 설비 증설 작업에 따른 별도 비용이 부과 될 수 있습니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>현장 환경에 따라 설치가 불가능할 경우에도 기본 출장 비용은 부과될 수 있습니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>욕실 가구 설치 시 위생도기 설치를 위한 상판 타공이 필요할 경우 별도 비용이 부과 될 수 있습니다.</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </page-section> -->
        </center-layout>
    </client-page>
</template>

<script>
import { BOARD_CATEGORIES__JFL_GUIDES, btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";

import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
    },
    data: () => ({
        btn_primary,
        category: BOARD_CATEGORIES__JFL_GUIDES.INSTALLATION.value,
    }),
};
</script>

<style lang="scss" scoped>
.v-data-table.text-center > .v-data-table__wrapper > table > thead > tr > th {
    text-align: center;
}
.v-data-table--default.v-data-table--border {
    > .v-data-table__wrapper > table {
        > tbody,
        > thead {
            > tr {
                > th,
                > td {
                    line-height: 1.35;
                    padding: 8px;
                }
            }
        }
    }
}
</style>
