<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <div class="concierge-area">
                <v-row>
                    <v-col md="6" order-md="2" order-lg="1" class="concierge__left d-none d-md-block">
                        <div class="h-md-100 d-flex align-end">
                            <div class="concierge-img-wrap">
                                <v-responsive :aspect-ratio="430 / 390" class="w-100"></v-responsive>                    
                                <v-img src="/images/concierge/concierge-img.png" max-width="498" :aspect-ratio="498 / 390" class="concierge-img" />
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="12" lg="4" order-lg="2" class="concierge__center">
                        <div class="pr-md-20px text-center text-md-left">
                            <div class="tit-wrap">
                                <h2 class="tit line-height-1">컨시어지 서비스</h2>
                            </div>
                            <p class="txt txt--sm txt--dark line-height-15">
                                조이포라이프는 고객 여러분의 편리한 쇼핑을 돕고 서비스를 이용함에 있어 최상의 만족을 드릴 수 있도록 전담팀을 운영하고 있습니다. 제품에 대한 궁금증을 해결해 드리는 제품 상담과 오프라인 쇼룸, 설치에 관한 기술지원 및 안내, 그리고 사후 A/S 부분까지 직접 담당자를 통해 고객께서 원하시는 서비스를 빠르게 받으실 수 있도록 항상 최선을 다하고 있습니다. 
                            </p>
                            <div class="mt-20px mt-md-40px">
                                <h3 class="tit tit--xs primary--text mb-4px mb-md-8px">프리미엄을 산다 기분좋게 산다 </h3>
                                <p class="txt txt--sm txt--dark font-weight-bold">
                                    조이포라이프 공식몰
                                </p>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" order-md="3" class="concierge__right mt-20px mt-md-0">
                        <div class="px-12px pl-md-20px pr-lg-20px">
                            <v-card flat tile max-width="370" color="grey lighten-5" class="mx-auto mx-md-0">
                                <div class="pa-20px pa-md-30px pa-lg-40px">
                                    <h3 class="tit tit--xs">컨시어지 서비스</h3>
                                    <concierge-input />
                                </div>
                            </v-card>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ConciergeInput from "@/components/client/concierge/concierge-input.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ConciergeInput,
    },
};
</script>

<style lang="scss" scoped>
.concierge-area{
    --padding-bottom: 40px;
    padding-bottom: var(--padding-bottom);
    position: relative;
    &::before{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: calc(var(--padding-bottom) * 2);
        bottom: 0;
        left: 0;
        background-color: var(--v-primary-base);
        z-index: -1;
    }
}
.concierge-img{
    width: calc(100% + var(--container-gutter));
    position: absolute;
    bottom: 0;
    right: 0;
    &-wrap{
        max-width: 430px;
        width: 100%;
        position: relative;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .concierge-area{
        --padding-bottom: 80px;
    }
}
@media (min-width:1024px){
    .concierge-img{
        min-width: 400px;
    }
    .concierge{
        &__left{
            flex: 0 0 25%;
            max-width: 25%;
        }
        &__right{
            flex: 0 0 41.6666666667%;
            max-width: 41.6666666667%;
        }
    }
}
@media (min-width:1200px){
}
@media (min-width:1440px){
    .concierge-img{
        min-width: 498px;
    }
    .concierge{
        &__left{
            flex: 0 0 33.3333333333%;
            max-width: 33.3333333333%;
        }
        &__right{
            flex: 0 0 33.3333333333%;
            max-width: 33.3333333333%;
        }
    }
}

</style>
