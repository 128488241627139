<template>
    <client-page>
        <community-layout title="공지사항">
            <page-section class="py-0">
                <notification-list v-if="!$route.params._notification"></notification-list>
                <notification-view v-else></notification-view>
            </page-section>
        </community-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import CommunityLayout from "@/components/client/templates/community-layout.vue";
import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        CommunityLayout,
        NotificationList,
        NotificationView,
    },
};
</script>
