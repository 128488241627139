var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "concierge-area"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "concierge__left d-none d-md-block",
    attrs: {
      "md": "6",
      "order-md": "2",
      "order-lg": "1"
    }
  }, [_c('div', {
    staticClass: "h-md-100 d-flex align-end"
  }, [_c('div', {
    staticClass: "concierge-img-wrap"
  }, [_c('v-responsive', {
    staticClass: "w-100",
    attrs: {
      "aspect-ratio": 430 / 390
    }
  }), _c('v-img', {
    staticClass: "concierge-img",
    attrs: {
      "src": "/images/concierge/concierge-img.png",
      "max-width": "498",
      "aspect-ratio": 498 / 390
    }
  })], 1)])]), _c('v-col', {
    staticClass: "concierge__center",
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "4",
      "order-lg": "2"
    }
  }, [_c('div', {
    staticClass: "pr-md-20px text-center text-md-left"
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit line-height-1"
  }, [_vm._v("컨시어지 서비스")])]), _c('p', {
    staticClass: "txt txt--sm txt--dark line-height-15"
  }, [_vm._v(" 조이포라이프는 고객 여러분의 편리한 쇼핑을 돕고 서비스를 이용함에 있어 최상의 만족을 드릴 수 있도록 전담팀을 운영하고 있습니다. 제품에 대한 궁금증을 해결해 드리는 제품 상담과 오프라인 쇼룸, 설치에 관한 기술지원 및 안내, 그리고 사후 A/S 부분까지 직접 담당자를 통해 고객께서 원하시는 서비스를 빠르게 받으실 수 있도록 항상 최선을 다하고 있습니다. ")]), _c('div', {
    staticClass: "mt-20px mt-md-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs primary--text mb-4px mb-md-8px"
  }, [_vm._v("프리미엄을 산다 기분좋게 산다 ")]), _c('p', {
    staticClass: "txt txt--sm txt--dark font-weight-bold"
  }, [_vm._v(" 조이포라이프 공식몰 ")])])])]), _c('v-col', {
    staticClass: "concierge__right mt-20px mt-md-0",
    attrs: {
      "cols": "12",
      "md": "6",
      "order-md": "3"
    }
  }, [_c('div', {
    staticClass: "px-12px pl-md-20px pr-lg-20px"
  }, [_c('v-card', {
    staticClass: "mx-auto mx-md-0",
    attrs: {
      "flat": "",
      "tile": "",
      "max-width": "370",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-20px pa-md-30px pa-lg-40px"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("컨시어지 서비스")]), _c('concierge-input')], 1)])], 1)])], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }