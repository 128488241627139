<template>
    <div v-bind="$attrs">
        <v-row class="center-layout-row">
            <v-col class="center-layout-row__aside">
                <div class="center-layout-row__aside-inner">
                    <center-aside />
                </div>
            </v-col>
            <v-col class="center-layout-row__contents">
                <div class="pb-20px pb-md-40px">
                    <v-row align="center">
                        <v-col cols="12" md="">
                            <tit-wrap-subtitle v-bind="$attrs" class="mb-0">
                                <template #tit>{{ title }}</template>
                                <template v-if="subTitle" #subTit>{{ subTitle }}</template>
                            </tit-wrap-subtitle>
                        </v-col>
                        <v-col v-if="this.$slots['titleRight']" cols="12" md="auto" class="text-right">
                            <slot name="titleRight" />
                        </v-col>
                    </v-row>
                </div>

                <slot />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CenterAside from "@/components/client/center/aside/center-aside.vue";
import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";
import { mapState } from "vuex";
export default {
    props: {
        title: { type: String },
        subTitle: { type: String },
        titleSpacingLg: { type: Boolean },
        titleRight: { type: Boolean },
        aside: { type: String, default: "" },
    },
    components: {
        CenterAside,
        TitWrapSubtitle,
    },
    mounted() {},
    destroyed() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.center-layout-row {
    --grid-gutter-center: 10px;
    --aside-width: 240px;
    @media (min-width: 1400px) {
        --grid-gutter-center: 14px;
    }
    @media (min-width: 1600px) {
        --grid-gutter-center: 14px;
    }
    @media (min-width: 1700px) {
        --grid-gutter-center: 20px;
    }
    @media (min-width: 1800px) {
        --grid-gutter-center: 30px;
    }
    margin: calc(var(--grid-gutter-center) * -1) !important;
    > [class*="col"] {
        padding: var(--grid-gutter-center) !important;
    }

    &__aside,
    &__contents {
        flex: 0 0 100%;
        max-width: 100%;
    }
    &__aside-inner {
        width: 100%;
        margin-bottom: 20px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .center-layout-row {
        &__aside {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        &__aside-inner {
            width: var(--aside-width);
            margin-bottom: 0;
        }
        &__contents {
            flex-basis: 0;
            flex-grow: 1;
            max-width: calc(100% - var(--aside-width) - (var(--grid-gutter-center) * 2));
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .center-layout-row {
        &__aside,
        &__contents {
            flex: 0 0 100%;
            max-width: 100%;
        }
        &__aside-inner {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}
@media (min-width: 1400px) {
    .center-layout-row {
        &__aside {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        &__aside-inner {
            margin-bottom: 0;
        }
        &__contents {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
    }
}
</style>
