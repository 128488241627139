<template>
    <client-page>
        <community-layout title="커뮤니티" v-bind="{ subTitle }">
            <page-section class="py-0">
                <router-view name="board" code="community" v-bind="{ category }" skin="board-community" :limit="8" />
            </page-section>
        </community-layout>
    </client-page>
</template>

<script>
import { BOARD_CATEGORIES__COMMUNITY, btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import CommunityLayout from "@/components/client/templates/community-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        CommunityLayout,
        PageSection,
    },
    props: {
        category: { type: String, default: "news" },
    },
    data: () => ({
        btn_primary,
    }),
    computed: {
        subTitle() {
            return BOARD_CATEGORIES__COMMUNITY[this.category]?.text;
        },
    },
};
</script>
