var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tile": "",
      "tag": "aside",
      "width": "100%"
    }
  }, [_vm.detect == 'pc' ? _c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }) : _vm._e(), _c('aside-navigation', _vm._b({}, 'aside-navigation', {
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }