<template>
    <client-page>
        <center-layout title="JFL 케어" subTitle="딜리버리 설치 서비스">
            <page-section class="py-0">
                <v-card color="grey lighten-5">
                    <div class="pa-20px pa-md-40px">
                        <h3 class="tit tit--xs line-height-135">클릭 한 번으로 배달부터 설치까지 한번에!</h3>
                        <p class="txt txt--sm txt--dark mt-8px">고객분들의 집이 예뻐지는 그 날까지 조이포라이프가 길잡이가 되어 드리겠습니다.</p>
                        <p class="txt txt--xs txt--light mt-16px mt-md-20px">*서비스 가능 지역을 먼저 확인해 주시고 설치비용은 제품 및 지역에 따라 다르게 책정될 수 있습니다.</p>
                        <div class="btn-wrap">
                            <v-btn :to="`${$route.path}/inquire`" v-bind="{ ...btn_primary, ...$attrs }" class="min-w-120px">문의하기</v-btn>
                        </div>
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">배송·설치 서비스 리얼 고객 후기</h4>
                </div>

                <v-row>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">제 로망을 이뤘어요~</div>
                                    <div class="txt txt--xs">신혼으로 우리만의 예쁜 욕실을 가지고 싶은 로망이 있어 출장 컨설팅을 신청했어요. 컨설팅을 통해서 제품과 조명, 타일까지 꼼꼼하게 상담해 주셔서 너무 예쁜 욕실이 완성된 거 같어요!</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[기업회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">신속한 처리 만족합니다.</div>
                                    <div class="txt txt--xs">조이포라이프 공식몰은 전담팀이 따로 있다보니 신속한 상담을 받을 수 있어 좋았습니다. 또한 공시일자가 앞당겨져서 택배로 받을 경우 일정을 맞추기 어려운 상황이었는데 상담원이 화물출고 처리를 도와줘서 일정을 맞출수 있었어요.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[개인회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">늘 애용하는 조이포라이프!!</div>
                                    <div class="txt txt--xs">주문한 상품의 부품 1개가 파손돼 고객센터로 문의했는데 빠르게 교환 처리를 받을 수 있었습니다. 굉장히 만족스럽네요. 전담팀이 있으니 이런 서비스들을 빨리 처리해 주셔서 계속 애용할 것 같습니다.</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[기업회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card outlined color="grey darken-4" class="overflow-hidden h-100">
                            <div class="pa-16px pa-md-20px">
                                <div>
                                    <div class="txt txt--sm txt--dark font-weight-bold mb-8px">친절하고 좋아요!</div>
                                    <div class="txt txt--xs">시공일자에 맞추기 위해 특정 날짜에 수령 할 수 있도록 요청 했었어요~ 정말 그 날짜에 맞춰 늦지 않게 상품이 도착했더라구요. 차질없이 시공 잘 마무리 했습니다. 앞으로도 잘 부탁드리겠습니다. ㅎㅎ</div>
                                </div>
                                <div class="d-flex flex-wrap align-center txt txt--xs txt--dark mt-12px">
                                    <span class="pr-8px">[개인회원]</span><span class="pr-8px"><b>joeylike</b>님</span>
                                    <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="2" class="mt-n2px" />
                                </div>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">서비스 프로세스</h4>
                </div>

                <v-row class="row--sm">
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 1</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">배송+설치서비스 신청</span>
                                <span class="txt txt--sm txt--dark">제품 구매시 해당 서비스 신청</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 2</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">해피콜-스케쥴 조정</span>
                                <span class="txt txt--sm txt--dark">주문 및 신청 확인 후 전담부서에서 연락</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 3</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">서비스 진행</span>
                                <span class="txt txt--sm txt--dark">전문 설치기사님이 배송 및 설치서비스 진행</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">출장 컨설팅 신청 예시</h4>
                </div>
                <v-row class="row--xs">
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>필히 현장에 대한 사진 및 영상을 보내주셔야 좀 더 정확한 컨설팅이 가능합니다.</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>현장을 방문하지 못하는 관계로 정확한 설치 여부에 대해 파악이 어려울 수 있습니다.</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--xxs txt txt--sm txt--dark">
                            <v-col cols="auto">·</v-col>
                            <v-col>출장 컨설팅 서비스와 비교해 서비스 제한이 있을 수 있습니다.</v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-card outlined class="mt-20px">
                    <div class="pa-20px pa-md-40px pa-xl-60px">
                        <v-img max-width="740" :aspect-ratio="740 / 576" class="mx-auto" src="/images/center/delivery-installation-service-img.png" />
                    </div>
                </v-card>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">현장 서비스 세부안내</h4>
                </div>

                <v-row class="row--sm">
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 1</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">방문 현상 상황 체크</span>
                                <span class="txt txt--sm txt--dark">제품 설치 전 현장상황 점검 및 협의</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 2</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">보양 처리 및 제품 탈거</span>
                                <span class="txt txt--sm txt--dark">깨끗한 설치환경을 위한 보양 작업 및 탈거 진행 작업</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 3</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">구매 제품 설치</span>
                                <span class="txt txt--sm txt--dark">설치 메뉴얼에 따른 설치작업 진행</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row align-md="center" class="row--sm">
                            <v-col cols="auto">
                                <v-chip small outlined color="primary" class="font-weight-bold border-2">STEP 4</v-chip>
                            </v-col>
                            <v-col>
                                <span class="txt txt--sm font-weight-bold primary--text pr-8px pr-md-12px">폐기물 수거/마무리</span>
                                <span class="txt txt--sm txt--dark">폐기물 수거 및 현장 클리닝 후 마무리</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">배송·설치서비스 카테고리별 배송 방식</h4>
                </div>
                <v-data-table :headers="ShippingMethodHeaders" dense hide-default-footer disable-filtering disable-sort mobile-breakpoint="0" class="v-data-table--default v-data-table--no-hover text-center">
                    <template #body>
                        <tbody>
                            <tr>
                                <td>택배서비스</td>
                                <td></td>
                                <td><i class="icon icon-round-check mx-auto" /></td>
                                <td><i class="icon icon-round-check mx-auto" /></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>퀵서비스</td>
                                <td><i class="icon icon-round-check mx-auto" /></td>
                                <td><i class="icon icon-round-check mx-auto" /></td>
                                <td><i class="icon icon-round-check mx-auto" /></td>
                                <td><i class="icon icon-round-check mx-auto" /></td>
                                <td><i class="icon icon-round-check mx-auto" /></td>
                            </tr>
                            <tr>
                                <td>전시장픽업서비스</td>
                                <td><i class="icon icon-round-check mx-auto" /></td>
                                <td><i class="icon icon-round-check mx-auto" /></td>
                                <td><i class="icon icon-round-check mx-auto" /></td>
                                <td><i class="icon icon-round-check mx-auto" /></td>
                                <td><i class="icon icon-round-check mx-auto" /></td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">제품별 배송서비스 지역안내</h4>
                </div>
                <v-data-table :headers="AreaGuideHeaders" dense hide-default-footer disable-filtering disable-sort mobile-breakpoint="0" class="v-data-table--default v-data-table--no-hover text-center">
                    <template #body>
                        <tbody>
                            <tr>
                                <td>택배서비스</td>
                                <td><span class="red--text text--darken-3">불가</span></td>
                                <td>전국</td>
                                <td>전국</td>
                                <td><span class="red--text text--darken-3">불가</span></td>
                                <td>전국</td>
                            </tr>
                            <tr>
                                <td>퀵서비스</td>
                                <td><span class="primary--text">서울/경기</span></td>
                                <td>전국</td>
                                <td>전국</td>
                                <td><span class="primary--text">서울/경기</span></td>
                                <td>전국</td>
                            </tr>
                            <tr>
                                <td>전시장픽업서비스</td>
                                <td>판교쇼룸</td>
                                <td>판교쇼룸</td>
                                <td>판교쇼룸</td>
                                <td>판교쇼룸</td>
                                <td>판교쇼룸</td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">추가 비용 항목</h4>
                </div>

                <v-row justify="center" class="row--lg row-cols-xl-5">
                    <v-col cols="6" sm="4" lg="3">
                        <div class="border border-dark rounded-pill">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex align-center justify-center">
                                <div class="pa-14px text-center">
                                    <i class="icon icon-carrying mx-auto mb-8px" />
                                    <div class="txt txt--sm txt--dark">배송 및 운반이 <br class="d-none d-md-block" />어려운 환경</div>
                                </div>
                            </v-responsive>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" lg="3">
                        <div class="border border-dark rounded-pill">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex align-center justify-center">
                                <div class="pa-14px text-center">
                                    <i class="icon icon-texture mx-auto mb-8px" />
                                    <div class="txt txt--sm txt--dark">특수 재질인 경우</div>
                                </div>
                            </v-responsive>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" lg="3">
                        <div class="border border-dark rounded-pill">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex align-center justify-center">
                                <div class="pa-14px text-center">
                                    <i class="icon icon-wiring mx-auto mb-8px" />
                                    <div class="txt txt--sm txt--dark">배선 이동 작업</div>
                                </div>
                            </v-responsive>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" lg="3">
                        <div class="border border-dark rounded-pill">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex align-center justify-center">
                                <div class="pa-14px text-center">
                                    <i class="icon icon-perforated mx-auto mb-8px" />
                                    <div class="txt txt--sm txt--dark">타공 작업</div>
                                </div>
                            </v-responsive>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" lg="3">
                        <div class="border border-dark rounded-pill">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex align-center justify-center">
                                <div class="pa-14px text-center">
                                    <i class="icon icon-expansion mx-auto mb-8px" />
                                    <div class="txt txt--sm txt--dark">증설 작업</div>
                                </div>
                            </v-responsive>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" lg="3" class="pt-lg-0">
                        <div class="border border-dark rounded-pill">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex align-center justify-center">
                                <div class="pa-14px text-center">
                                    <i class="icon icon-bathroom-expansion mx-auto mb-8px" />
                                    <div class="txt txt--sm txt--dark">욕실 설비 증설 작업</div>
                                </div>
                            </v-responsive>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" lg="3" class="pt-lg-0">
                        <div class="border border-dark rounded-pill">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex align-center justify-center">
                                <div class="pa-14px text-center">
                                    <i class="icon icon-electrical mx-auto mb-8px" />
                                    <div class="txt txt--sm txt--dark">욕실 전기 배선 작업</div>
                                </div>
                            </v-responsive>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" lg="3" class="pt-lg-0">
                        <div class="border border-dark rounded-pill">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex align-center justify-center">
                                <div class="pa-14px text-center">
                                    <i class="icon icon-additional-perforation mx-auto mb-8px" />
                                    <div class="txt txt--sm txt--dark">제품 추가 타공 작업</div>
                                </div>
                            </v-responsive>
                        </div>
                    </v-col>
                    <v-col cols="6" sm="4" lg="3" class="pt-lg-0">
                        <div class="border border-dark rounded-pill">
                            <v-responsive :aspect-ratio="1 / 1" content-class="d-flex align-center justify-center">
                                <div class="pa-14px text-center">
                                    <i class="icon icon-demolition mx-auto mb-8px" />
                                    <div class="txt txt--sm txt--dark">기타 추가 철거 작업</div>
                                </div>
                            </v-responsive>
                        </div>
                    </v-col>
                </v-row>
            </page-section>

            <page-section class="page-section--sm pb-0">
                <div class="tit-wrap tit-wrap--sm">
                    <h4 class="tit tit--xs">배송·설치 서비스 비용산출방식</h4>
                </div>

                <v-card color="grey lighten-5">
                    <div class="pa-16px pa-md-20px">
                        <v-row class="row--xs">
                            <v-col cols="12">
                                <div class="txt txt--sm primary--text font-weight-bold">서비스비용 = ❶배송비 + ❷설치비 + ❸철거비 + ❹폐기물 수거비</div>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>욕조는 부피 및 무게로 인한 추가 비용이 발생할 수 있으니 고객센터에 꼭 확인하신 후 딜리버리 신청해 주시기 바랍니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>해당 서비스는 유료 서비스이며, 비용은 위 안내표를 확인해 주시기 바랍니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>조이포라이프 공식몰을 통해 구매하신 제품만 신청 가능한 서비스입니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>출장 비용은 지역에 따라서 변경 될 수 있습니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>환경에 따라 설치가 어려운 경우도 별도의 비용이 발생할 수 있습니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>제품 불량 외 설치 완료된 제품은 교환 및 반품이 불가합니다.</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>기존 설치되어 있던 제품은 수거하지 않습니다. (확인요망)</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row class="row--xxs txt txt--sm txt--dark">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>
                                        배선 및 타공 등 별도의 작업이 있을 시 추가 비용이 발생할 수 있으니 자세한 상담을 받아보시길 추천 드립니다.
                                        <p class="txt txt--xs red--text">모든 환경이 동일하지 않으므로 추가적인 작업에 대한 정확한 정보는 현장 방문을 통해 확인 후 안내받으실 수 있습니다.</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
            </page-section>
        </center-layout>
    </client-page>
</template>

<script>
import { btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import CenterLayout from "@/components/client/templates/center-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        CenterLayout,
        PageSection,
    },
    data: () => {
        return {
            btn_primary,

            ShippingMethodHeaders: [
                { text: "배송/제품", value: "Title1", align: "center", width: "16.6666%" },
                { text: "양변기/욕조", value: "Title2", align: "center", width: "16.6666%" },
                { text: "세면기", value: "Title3", align: "center", width: "16.6666%" },
                { text: "수전", value: "Title4", align: "center", width: "16.6666%" },
                { text: "가구", value: "Title5", align: "center", width: "16.6666%" },
                { text: "악세사리/부속", value: "Title6", align: "center", width: "16.6666%" },
            ],

            AreaGuideHeaders: [
                { text: "배송/제품", value: "Title1", align: "center", width: "16.6666%" },
                { text: "양변기/욕조", value: "Title2", align: "center", width: "16.6666%" },
                { text: "세면기", value: "Title3", align: "center", width: "16.6666%" },
                { text: "수전", value: "Title4", align: "center", width: "16.6666%" },
                { text: "가구", value: "Title5", align: "center", width: "16.6666%" },
                { text: "악세사리/부속", value: "Title6", align: "center", width: "16.6666%" },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.gallery-img {
    width: 100%;
    padding-top: calc(164 / 248 * 100%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
</style>
